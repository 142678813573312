import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Backdrop from '@mui/material/Backdrop';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Tooltip from '@mui/material/Tooltip';
import ErrorIcon from '@mui/icons-material/Error';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import { Snackbar, Alert } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, FormControl, InputLabel, Select, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, InputAdornment } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import sampleImage from '../assets/paper.png';
import Navbar from './Navbar';
import axios from 'axios'; // Import axios for making HTTP requests
import { fontSize } from '@mui/system';

const ManageMember = () => {
  const [members, setMembers] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [access, setAccess] = useState(true);
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [accessError, setAccessError] = useState('');
  const [memberToDelete, setMemberToDelete] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const inviteMemberEndpoint = `${process.env.REACT_APP_BACKEND_URL}/invitation/invite`; // Backend API endpoint
  const getOrgMembersEndpoint = `${process.env.REACT_APP_BACKEND_URL}/manageMember/org-members`; // Endpoint to fetch organization members
  const deleteMemberEndpoint = `${process.env.REACT_APP_BACKEND_URL}/manageMember/deleteTeam-member`; // Endpoint to delete a team member
  const adminUid = sessionStorage.getItem('uid');

  const cid = sessionStorage.getItem('cid');

  const fetchMembers = async () => {
    try {

      if (!cid) {
        console.error('No cid found in sessionStorage');
        setSnackbarMessage('No company ID found');
        setSnackbarOpen(true);
        return;
      }

      const response = await axios.post(getOrgMembersEndpoint, { cid });
      const { data } = response;

      console.log('API response:', data);

      if (data.success && Array.isArray(data.org_members)) {
        const formattedMembers = data.org_members
          .filter(member => member.status !== false)  // Filter out deleted members
          .map(({ uname, uid, email, cid, permission, joined_date, status, role }) => ({
            uid,
            uname,
            email,
            cid,
            role,
            permissions: permission.toLowerCase() === 'read/write',
            joined_date: new Date(joined_date).toLocaleDateString('en-US', { month: 'numeric', day: 'numeric', year: 'numeric' }),
            status
          }));
        if (JSON.stringify(formattedMembers) !== JSON.stringify(members)) {
          setMembers(formattedMembers);
          console.log('Members list updated');
        }
      }
    } catch (error) {
      console.error('Error fetching members:', error);

    }
  };

  const pollForNewMembers = () => {
    const interval = setInterval(() => {
      fetchMembers();
    }, 100000);

    return () => clearInterval(interval);
  };


  useEffect(() => {
    fetchMembers();
    const cleanup = pollForNewMembers();
    return cleanup;
  }, []);


  const validateFields = () => {
    let valid = true;
    setNameError('');
    setEmailError('');
    setAccessError('');

    if (!name) {
      setNameError('Username is required');
      valid = false;
    }
    if (!email) {
      setEmailError('Email is required');
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError('Email is not valid');
      valid = false;
    }


    return valid;
  };

  const handleAddMember = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setName('');
    setEmail('');
    setAccess(true);
    setNameError('');
    setEmailError('');
  };


  const handleInviteMember = async () => {
    if (!validateFields()) {
      return;
    }
    setIsLoading(true);
    handleCloseModal();

    const cid = sessionStorage.getItem('cid');
    if (!cid) {
      setSnackbarMessage('No organization ID found. Please log in again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      setIsLoading(false);
      return;
    }

    const newMember = {
      cid,
      name,
      email,
      permissions: access
    };

    try {
      const response = await axios.post(inviteMemberEndpoint, newMember);
      const { data } = response;
      console.log('Invite API response:', data);
      console.log('New Member Data:', newMember);

      if (data.success) {
        setSnackbarMessage('Invitation sent successfully');
        setSnackbarSeverity('success');
      } else {
        setSnackbarMessage('Failed to send invitation');
        setSnackbarSeverity('error');
      }
    } catch (error) {
      console.error('Error sending invite:', error);
      if (error.response) {
        // Check if the error response is known
        if (error.response.status === 400 && error.response.data.error === "Email already exists") {
          setSnackbarMessage('Member with the same email already exists. Please invite another member.');
        } else {
          setSnackbarMessage('Failed to send invitation: ' + (error.response.data.message || 'An error occurred'));
        }
        setSnackbarSeverity('error');
      } else {
        setSnackbarMessage('Network error, please try again later.');
        setSnackbarSeverity('error');
      }
    } finally {
      setSnackbarOpen(true);
      setIsLoading(false);
    }
  };


  const handleOpenDialog = (memberUid) => {
    setMemberToDelete(memberUid);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setMemberToDelete(null);
  };
  const deleteMember = async (memberUid) => {
    if (!adminUid) {
      setSnackbarMessage('Missing admin ID. Please log in again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    try {
      const response = await axios.delete(deleteMemberEndpoint, {
        data: { adminUid, memberUid }
      });
      const { data } = response;
      console.log("memberuid", memberUid);
      console.log("admin", adminUid);

      console.log('Delete API response:', data);
      if (data.success) {
        setSnackbarMessage('Team member deleted successfully');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);

        setMembers(prevMembers => prevMembers.filter(member => member.uid !== memberUid));
        fetchMembers();

      } else {
        setSnackbarMessage('Failed to delete member');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Error deleting member:', error);
      setSnackbarMessage('Failed to delete member');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };



  const handleDeleteMember = () => {
    if (memberToDelete) {
      deleteMember(memberToDelete);
      handleCloseDialog();
    }
  };



  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredMembers = members.filter((member) =>
    member &&
    member.uname &&
    member.uname.toLowerCase().includes(searchQuery.toLowerCase()) &&
    member.status !== false
  );

  const CustomArrowDropDownIcon = () => (
    <ArrowDropDownIcon sx={{ color: '#8133F1' }} />
  );


  return (
    <div>
      <Navbar />
      <div
        className="main-content"
        style={{
          fontFamily: 'Poppins, sans-serif',
          margin: '80px 40px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {members.length > 0 ? (
          <>
            <TextField
              placeholder="Search by Username"
              value={searchQuery}
              onChange={handleSearch}
              variant="outlined"
              sx={{
                width: '280px',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'rgba(0, 0, 0, 0.38)', // grey 600
                  },
                  '&:hover fieldset': {
                    borderColor: '#424242', // grey 800
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#424242', // grey 800
                  },
                },
                '& .MuiInputBase-input::placeholder': {
                  color: 'rgba(0, 0, 0, 0.6)',
                  opacity: 1,
                  fontSize: '11px',
                  fontFamily: 'Poppins, sans-serif',
                },
              }}
              InputProps={{
                style: { height: '31px' },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddMember}
              sx={{
                backgroundColor: '#8133F1',
                textTransform: 'none',
                fontSize: '14px',  // Minimum text size requirement
                minWidth: '106px',
                height: '36px',
                padding: '0 16px',  // Minimum padding left-right
                '&:hover': {
                  backgroundColor: '#5900D9',
                },
                '& .MuiButton-label': {
                  textAlign: 'center',
                },
              }}
            >
              Add Member
            </Button>
          </>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddMember}
              sx={{
                backgroundColor: '#8133F1',
                textTransform: 'none',
                fontSize: '14px',  // Minimum text size requirement
                minWidth: '106px',
                height: '36px',
                padding: '0 16px',  // Minimum padding left-right
                '&:hover': {
                  backgroundColor: '#5900D9',
                },
                '& .MuiButton-label': {
                  textAlign: 'center',
                },
              }}
            >
              Add Member
            </Button>
          </div>
        )}
      </div>

      {members.length === 0 && (
        <div
          className="centered-content"
          style={{
            marginTop: '100px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={sampleImage} alt="Sample" className="main-image" style={{ width: '150px', height: '150px' }} />
          <p className="no-members-text" style={{ marginTop: '10px', fontSize: '0.8rem', marginLeft: '-30px' }}>
            No members currently available
          </p>
        </div>
      )}

      {members.length > 0 && filteredMembers.length === 0 && (
        <div style={{ textAlign: 'center', marginTop: '50px', fontSize: "12px" }}>
          <p>No members found matching your search.</p>
        </div>
      )}

      {members.length > 0 && filteredMembers.length > 0 && (
        <div style={{ marginLeft: '40px', marginRight: '40px' }}>
          <TableContainer component={Paper} style={{ marginTop: '30px' }}>
            <Table sx={{
              minWidth: 650,
              '& .MuiTableCell-root': {
                borderBottom: '1px solid rgba(0, 0, 0, 0.3)',
              },
            }}
              size="small" aria-label="members table">
              <TableHead>
                <TableRow sx={{ backgroundColor: '#A37CF0', height: '10px' }}>
                  <TableCell sx={{ color: 'white', fontSize: '13px', padding: '1px 8px', height: '10px', textAlign: 'center' }}>Username</TableCell>
                  <TableCell sx={{ color: 'white', fontSize: '13px', padding: '1px 8px', height: '10px', textAlign: 'center' }}>Email</TableCell>
                  <TableCell sx={{ color: 'white', fontSize: '13px', padding: '1px 8px', height: '10px', textAlign: 'center' }}>Join Date</TableCell>
                  <TableCell sx={{ color: 'white', fontSize: '13px', padding: '1px 8px', height: '10px', textAlign: 'center' }}>Permission</TableCell>
                  <TableCell sx={{ color: 'white', fontSize: '13px', padding: '1px 8px', height: '10px', textAlign: 'center' }}>Actions</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {filteredMembers.map((member, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ padding: '1px 8px', fontSize: '13px', height: '5px', textAlign: 'center' }}>{member.role === 'admin' ? `${member.uname} (Admin)` : member.uname || 'N/A'}
                    </TableCell>
                    <TableCell sx={{ padding: '1px 8px', fontSize: '13px', height: '5px', textAlign: 'center' }}>{member.email || 'N/A'}</TableCell>
                    <TableCell sx={{ padding: '1px 8px', fontSize: '13px', height: '5px', textAlign: 'center' }}>{member.joined_date || 'N/A'}</TableCell>
                    <TableCell sx={{ padding: '1px 8px', fontSize: '13px', height: '5px', textAlign: 'center' }}>
                      {member.permissions ? 'Read/Write' : 'Read'}
                    </TableCell>
                    <TableCell sx={{ padding: '1px 8px', height: '5px', textAlign: 'center' }}>
                      <Tooltip
                            title="Delete Member"
                            leaveDelay={0}
                            disableInteractive
                          >
                        <IconButton
                          color="primary"
                          sx={{
                            color: '#575757', padding: '4px',
                            '&:hover': {
                              color: 'red', // Change color to blue on hover
                            },
                          }}
                          onClick={() => handleOpenDialog(member.uid)}
                        >
                          <DeleteIcon sx={{ fontSize: "20px" }} />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}

      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            bgcolor: '#E7EBF8',
            boxShadow: 24,
            borderRadius: '5px',
            p: 4,
            fontFamily: 'Poppins, sans-serif',
          }}
        >
          <h2 style={{ fontSize: '1rem', marginTop: "1%" }}>Invite Member</h2>
          <TextField
            placeholder="Username"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            error={!!nameError}
            helperText={nameError}
            sx={{
              mb: 2,
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'rgba(0, 0, 0, 0.38)', // grey 600
                },
                '&:hover fieldset': {
                  borderColor: 'rgba(0, 0, 0, 0.62)', // grey 800
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'rgba(0, 0, 0, 0.62)', // grey 800
                },
              },
            }}
            InputProps={{
              style: {
                backgroundColor: 'white',
                fontFamily: 'Poppins, sans-serif',
                fontSize: "13px",
                height: '2.2rem', // Keeping the original height
              },
              inputProps: {
                style: {
                  paddingTop: '20px',
                },
              },
            }}
          />


          <TextField
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            type="email"
            error={!!emailError}
            helperText={emailError}
            sx={{
              mb: 2,
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'rgba(0, 0, 0, 0.38)', // grey 600
                },
                '&:hover fieldset': {
                  borderColor: 'rgba(0, 0, 0, 0.62)', // grey 800
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'rgba(0, 0, 0, 0.62)', // grey 800
                },
              },
            }}
            InputProps={{
              style: {
                backgroundColor: 'white',
                fontFamily: 'Poppins, sans-serif',
                height: '2.2rem',
                marginTop: '-6px',
                fontSize: "13px",
              },
              inputProps: {
                style: {
                  paddingTop: '20px',
                },
              },
            }}
          />

          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel
              sx={{
                fontSize: '13px',
                color: 'rgba(0, 0, 0, 0.38)', // Default color (grey 600)
                '&.Mui-focused': {
                  color: 'rgba(0, 0, 0, 0.62)', // Focused color (grey 800)
                },
              }}
            >
              Access
            </InputLabel>
            <Select
              value={access}
              onChange={(e) => setAccess(e.target.value)}
              label="Access"
              MenuProps={{
                MenuListProps: {
                  disablePadding: true,
                },
                PaperProps: {
                  elevation: 0,
                },
                IconComponent: () => <CustomArrowDropDownIcon />,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
                getContentAnchorEl: null,
              }}
              sx={{
                paddingTop: '3%',
                marginTop: '-6px',
                backgroundColor: 'white',
                fontFamily: 'Poppins, sans-serif',
                height: '2.2rem',
                fontSize: '13px',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(0, 0, 0, 0.38)', // grey 600
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(0, 0, 0, 0.62)', // grey 800
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(0, 0, 0, 0.62)', // grey 800
                },
                '&:hover .MuiSvgIcon-root': {
                  color: '#5900D9', // Black on hover
                },
                '& .MuiSvgIcon-root': {
                  color: '#8133F1', // Initial color (grey)
                  transition: 'color 0.3s ease',
                },
                '&:hover + .MuiInputLabel-root, &.Mui-focused + .MuiInputLabel-root': {
                  color: 'rgba(0, 0, 0, 0.62)', // grey 800 for label on hover and focus
                },
              }}
            >
              <MenuItem sx={{ fontSize: '13px', height: '1%' }} value={true}>
                Read/Write
              </MenuItem>
              <MenuItem sx={{ fontSize: '13px', height: '1%' }} value={false}>
                Read
              </MenuItem>
            </Select>
          </FormControl>
          <div style={{ textAlign: 'right' }}>
            <Button variant="contained" color="primary" onClick={handleCloseModal} sx={{
              minWidth: '48px',
              height: '21px',
              '&:hover': {
                backgroundColor: '#5900D9',
              },
              '& .MuiButton-label': {
                textAlign: 'center',
              },
              textTransform: 'none', mr: 1.5, backgroundColor: '#8133F1', color: 'white', fontSize: "11px"
            }}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleInviteMember}
              sx={{
                minWidth: '48px',
                height: '21px',
                '&:hover': {
                  backgroundColor: '#5900D9',
                },
                '& .MuiButton-label': {
                  textAlign: 'center',
                },
                textTransform: 'none',
                backgroundColor: '#8133F1',
                color: 'white',
                fontSize: "11px"
              }}
            >
              Invite
            </Button>

          </div>
        </Box>
      </Modal>
      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle sx={{ fontSize: "15px", backgroundColor: "#E7EBF8" }}>{"Confirm Delete"}</DialogTitle>
        <DialogContent sx={{ backgroundColor: "#E7EBF8" }} >
          <DialogContentText sx={{ fontSize: "14px" }}>
            Are you sure you want to delete this member?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ backgroundColor: "#E7EBF8" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCloseDialog}
            disabled={isLoading}
            sx={{
              minWidth: '48px',
              height: '21px',
              '&:hover': {
                backgroundColor: '#5900D9',
              },
              '& .MuiButton-label': {
                textAlign: 'center',
              },

              textTransform: 'none',
              mr: 0.3,
              backgroundColor: '#8133F1',
              color: 'white',
              fontSize: "11px"
            }}
          >
            Cancel
          </Button>
          <Button onClick={handleDeleteMember} variant="contained" color="primary" autoFocus sx={{
            textTransform: 'none',
            minWidth: '48px',
            height: '21px',
            '&:hover': {
              backgroundColor: '#5900D9',
            },
            '& .MuiButton-label': {
              textAlign: 'center',
            }, backgroundColor: '#8133F1', color: 'white', fontSize: "11px"
          }}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={
            snackbarSeverity === 'success'
              ? {
                backgroundColor: '#F8F8F8',
                color: '#878585',
                border: '1px solid #0DC42B',
                borderRadius: '4px',
                boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.3)',
              }
              : {
                backgroundColor: '#F8F8F8',
                color: '#878585',
                border: '1px solid red',
                borderRadius: '4px',
                boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.3)',
              }
          }
          iconMapping={{
            success: <CheckCircleIcon fontSize="inherit" style={{ color: '#16D835' }} />,
            error: <ErrorIcon fontSize="inherit" style={{ color: 'red' }} />,
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>


  );
};

export default ManageMember;

