// import React, { useState } from 'react';
// import { Container, Box, Button, TextField, Typography, InputAdornment, IconButton } from '@mui/material';
// import { Email, Lock, Visibility, VisibilityOff } from '@mui/icons-material';
// import { createTheme, ThemeProvider } from '@mui/material/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { useNavigate } from 'react-router-dom';
// import translationGif from '../assets/translation.gif';
// import axios from 'axios';

// const theme = createTheme({
//   palette: {
//     primary: {
//       main: '#30119E',
//     },
//     secondary: {
//       main: '#9EB0EA',
//     },
//   },
// });

// const Login = () => {
//   const [formValues, setFormValues] = useState({
//     email: '',
//     password: '',
//   });
//   const [formErrors, setFormErrors] = useState({});
//   const [loginError, setLoginError] = useState('');
//   const [showPassword, setShowPassword] = useState(false);
//   const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
//   const navigate = useNavigate();

//   const handleInputChange = (event) => {
//     const { name, value } = event.target;
//     setFormValues((prevValues) => ({
//       ...prevValues,
//       [name]: value,
//     }));
//     setFormErrors((prevErrors) => ({
//       ...prevErrors,
//       [name]: '',
//     }));
//   };

//   const validateForm = () => {
//     const errors = {};
//     const values = formValues;

//     if (!values.email) {
//       errors.email = 'Email is required';
//     } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email)) {
//       errors.email = 'Invalid email format';
//     }

//     if (!values.password) {
//       errors.password = 'Password is required';
//     }

//     return errors;
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     const errors = validateForm();

//     if (Object.keys(errors).length === 0) {
//       try {
//         const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/login`, {
//           email: formValues.email,
//           password: formValues.password,
//         });

//         console.log('Login response:', response.data);

//         if (response.data.login === "Success") {
//           // Store cid, uid, and role in session storage
//           sessionStorage.setItem('cid', response.data.cid);
//           sessionStorage.setItem('uid', response.data.uid);
//           sessionStorage.setItem('role', response.data.role);
//           sessionStorage.setItem('permissions', response.data.permissions);
//           sessionStorage.setItem('uname', response.data.uname);

//           console.log('Navigating to subscription page...');
//           navigate('/subscription');
//         } else {
//           setLoginError('Incorrect email or password');
//         }
//       } catch (error) {
//         console.error('Login error:', error);
//         console.error('Login error response:', error.response); // Log detailed error response
//         setLoginError('Incorrect Login credentials, please try again');
//       }
//     } else {
//       setFormErrors(errors);
//     }
//   };

//   const handleClickShowPassword = () => {
//     setShowPassword((prevShowPassword) => !prevShowPassword);
//   };

//   const handleMouseDownPassword = (event) => {
//     event.preventDefault();
//   };

//   return (
//     <ThemeProvider theme={theme}>
//       <Container
//         maxWidth={false}
//         disableGutters
//         sx={{
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'center',
//           height: '75vh',
//           backgroundColor: 'white',
//           marginTop: "30px"
//         }}
//       >
//         <Box
//           sx={{
//             marginTop: "120px",
//             width: '80%',
//             maxWidth: '1000px',
//             minHeight: { xs: '60%', sm: '480px', md: '530px', xl: '650px' },
//             display: 'flex',
//             flexDirection: isSmallScreen ? 'column' : 'row',
//             boxShadow: 3,
//             backgroundColor: '#F9F4FB',
//           }}
//         >
//           <Box
//             sx={{
//               width: isSmallScreen ? '100%' : '50%',
//               padding: 4,
//               display: 'flex',
//               flexDirection: 'column',
//               alignItems: 'center',
//               justifyContent: 'center',
//               position: 'relative',
//             }}
//           >
//             <Box component="form" sx={{ width: '72%', display: 'flex', flexDirection: 'column', alignItems: 'center' }} onSubmit={handleSubmit}>
//               <Typography variant="h5" component="h2" sx={{ mb: 2, fontWeight: 'bold', alignSelf: 'flex-start', color: '#8133F1' }}>
//                 Login
//               </Typography>
//               {loginError && (
//                 <Typography variant="body2" color="error" sx={{ mb: 2 }}>
//                   {loginError}
//                 </Typography>
//               )}
//               <TextField
//                 fullWidth
//                 variant="outlined"
//                 label="Email"
//                 name="email"
//                 size="small"
//                 value={formValues.email}
//                 onChange={handleInputChange}
//                 margin="normal"
//                 error={!!formErrors.email}
//                 helperText={formErrors.email}
//                 InputProps={{
//                   startAdornment: (
//                     <InputAdornment position="start">

//                       <Email
//                         sx={{
//                           fontSize: 24,
//                           color: '#707070',
//                           '&:hover': {
//                             color: '#4600A9',
//                           },
//                         }}
//                       />
//                     </InputAdornment>
//                   ),
//                 }}
//                 sx={{
//                   '& .MuiOutlinedInput-root': {
//                     '& fieldset': {
//                       borderColor: 'grey.600',
//                     },
//                     '&:hover fieldset': {
//                       borderColor: 'grey.800',
//                     },
//                     '&.Mui-focused fieldset': {
//                       borderColor: 'grey.800',
//                     },
//                   },
//                 }}
//               />
//               <TextField
//                 fullWidth
//                 variant="outlined"
//                 label="Password"
//                 name="password"
//                 size="small"
//                 type={showPassword ? 'text' : 'password'}
//                 value={formValues.password}
//                 onChange={handleInputChange}
//                 margin="normal"
//                 error={!!formErrors.password}
//                 helperText={formErrors.password}
//                 InputProps={{
//                   startAdornment: (
//                     <InputAdornment position="start">
//                       <Lock sx={{
//                         fontSize: 24,
//                         color: '#707070',
//                         '&:hover': {
//                           color: '#4600A9',
//                         },
//                       }} />
//                </InputAdornment>
//                   ),
//                   endAdornment: (
//                     <InputAdornment position="end">
//                       <IconButton
//                         aria-label="toggle password visibility"
//                         onClick={handleClickShowPassword}
//                         onMouseDown={handleMouseDownPassword}
//                         edge="end"
//                         sx={{ color: '#707070', '&:hover': { color: '#4600A9' } }}
//                       >
//  {showPassword ? <VisibilityOff sx={{ fontSize: 24 }} /> : <Visibility sx={{ fontSize: 24 }} />}
//      </IconButton>
//           </InputAdornment>
//                   ),
//                 }}
//                 sx={{
//                   '& .MuiOutlinedInput-root': {
//                     '& fieldset': {
//                       borderColor: 'grey.600',
//                     },
//                     '&:hover fieldset': {
//                       borderColor: 'grey.800',
//                     },
//                     '&.Mui-focused fieldset': {
//                       borderColor: 'grey.800',
//                     },
//                   },
//                 }}
//               />
//               <a
//                 href="/forgotpassword"
//                 style={{ color: '#8133F1', marginLeft: "160px", textDecoration: 'underline' }}
//                 onMouseEnter={(e) => e.currentTarget.style.color = '#5900D9'}
//                 onMouseLeave={(e) => e.currentTarget.style.color = '#8133F1'}
//               >
//                 Forgot password?
//               </a>
//               <Button
//                 type="submit"
//                 variant="contained"
//                 color="primary"
//                 sx={{
//                   height: '44px',
//                   width: { xs: '', sm: '310px' },
//                   backgroundColor: '#8133F1', // Color for the Login button
//                   '&:hover': {
//                     backgroundColor: '#5900D9', // Color on hover
//                   },
//                   textTransform: 'none',
//                   mt: 2,
//                   borderRadius: 2, // Adding border radius
//                 }}
//               >
//                 Login
//               </Button>
//               <Typography variant="body1" align="center" marginTop={"30px"}>
//                 No account?{' '}
//                 <a
//                   href="/register"
//                   style={{ color: '#8133F1', textDecoration: 'underline' }}
//                   onMouseEnter={(e) => e.currentTarget.style.color = '#5900D9'}
//                   onMouseLeave={(e) => e.currentTarget.style.color = '#8133F1'}
//                 >
//                   Create one.
//                 </a>
//               </Typography>
//             </Box>
//           </Box>
//           {!isSmallScreen && (
//             <Box
//               sx={{
//                 width: '50%',
//                 display: 'flex',
//                 alignItems: 'center',
//                 justifyContent: 'center',
//                 position: 'relative',
//                 backgroundColor: '#8133F1',
//                 overflow: 'hidden',
//               }}
//             >
//               <img
//                 src={translationGif}
//                 alt="Translation"
//                 style={{
//                   width: '100%',
//                   height: '100%',
//                   objectFit: 'cover',
//                   position: 'absolute',
//                 }}
//               />
//             </Box>
//           )}
//         </Box>
//       </Container>F
//     </ThemeProvider>
//   );
// };

// export default Login;

import React, { useState } from 'react';
import { Container, Box, Button, TextField, Typography, InputAdornment, IconButton } from '@mui/material';
import { Email, Lock, Visibility, VisibilityOff } from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import translationGif from '../assets/translation.gif';
import axios from 'axios';

const theme = createTheme({
  palette: {
    primary: {
      main: '#30119E',
    },
    secondary: {
      main: '#9EB0EA',
    },
  },
});

const Login = () => {
  const [formValues, setFormValues] = useState({
    email: '',
    password: '',
  });
  const [formErrors, setFormErrors] = useState({});
  const [loginError, setLoginError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const validateForm = () => {
    const errors = {};
    const values = formValues;

    if (!values.email) {
      errors.email = 'Email is required';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email)) {
      errors.email = 'Invalid email format';
    }

    if (!values.password) {
      errors.password = 'Password is required';
    }

    return errors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = validateForm();

    if (Object.keys(errors).length === 0) {
      try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/login`, {
          email: formValues.email,
          password: formValues.password,
        });

        console.log('Login response:', response.data);

        if (response.data.login === "Success") {
          sessionStorage.setItem('cid', response.data.cid);
          sessionStorage.setItem('uid', response.data.uid);
          sessionStorage.setItem('role', response.data.role);
          sessionStorage.setItem('permissions', response.data.permissions);
          sessionStorage.setItem('uname', response.data.uname);

          console.log('Navigating to subscription page...');
          navigate('/subscription');
        } else {
          setLoginError('Incorrect email or password');
        }
      } catch (error) {
        console.error('Login error:', error);
        console.error('Login error response:', error.response);
        setLoginError('Incorrect Login credentials, please try again');
      }
    } else {
      setFormErrors(errors);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '75vh',
          backgroundColor: 'white',
          marginTop: "30px"
        }}
      >
        <Box
          sx={{
            marginTop: "120px",
            width: '80%',
            maxWidth: '1000px',
            minHeight: { xs: '60%', sm: '480px', md: '530px', xl: '650px' },
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            boxShadow: 3,
            backgroundColor: '#F9F4FB',
          }}
        >
          <Box
            sx={{
              width: isSmallScreen ? '100%' : '50%',
              padding: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
            }}
          >
            <Box component="form" sx={{ width: '72%', display: 'flex', flexDirection: 'column', alignItems: 'center' }} onSubmit={handleSubmit}>
              <Typography variant="h5" component="h2" sx={{ mb: 2, fontWeight: 'bold', alignSelf: 'flex-start', color: '#8133F1' }}>
                Login
              </Typography>
              {loginError && (
                <Typography variant="body2" color="error" sx={{ mb: 2 }}>
                  {loginError}
                </Typography>
              )}
              <TextField
                fullWidth
                variant="outlined"
                label="Email"
                name="email"
                size="small"
                value={formValues.email}
                onChange={handleInputChange}
                margin="normal"
                error={!!formErrors.email}
                helperText={formErrors.email}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Email 
                        sx={{
                          fontSize: 24, 
                          color: '#707070', 
                          '&:hover': {
                            color: '#4600A9',
                          },
                        }} 
                      />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'grey.600',
                    },
                    '&:hover fieldset': {
                      borderColor: 'grey.800',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'grey.800',
                    },
                  },
                }}
              />
              <TextField
                fullWidth
                variant="outlined"
                label="Password"
                name="password"
                size="small"
                type={showPassword ? 'text' : 'password'}
                value={formValues.password}
                onChange={handleInputChange}
                margin="normal"
                error={!!formErrors.password}
                helperText={formErrors.password}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock 
                        sx={{
                          fontSize: 24, 
                          color: '#707070', 
                          '&:hover': {
                            color: '#4600A9',
                          },
                        }} 
                      />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        sx={{ color: '#707070', '&:hover': { color: '#4600A9' } }}
                      >
                        {showPassword ? <Visibility sx={{ fontSize: 24 }} /> : <VisibilityOff sx={{ fontSize: 24 }} />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'grey.600',
                    },
                    '&:hover fieldset': {
                      borderColor: 'grey.800',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'grey.800',
                    },
                  },
                }}
              />
              <a
                href="/forgotpassword"
                style={{ color: '#8133F1', marginLeft: "160px", textDecoration: 'underline' }}
                onMouseEnter={(e) => e.currentTarget.style.color = '#5900D9'}
                onMouseLeave={(e) => e.currentTarget.style.color = '#8133F1'}
              >
                Forgot password?
              </a>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{
                  height: '44px',
                  width: { xs: '', sm: '310px' },
                  backgroundColor: '#8133F1', // Color for the Login button
                  '&:hover': {
                    backgroundColor: '#5900D9', // Color on hover
                  },
                  textTransform: 'none',
                  mt: 2,
                  borderRadius: 2, // Adding border radius
                }}
              >
                Login
              </Button>
              <Typography variant="body1" align="center" marginTop={"30px"}>
                No account?{' '}
                <a
                  href="/register"
                  style={{ color: '#8133F1', textDecoration: 'underline' }}
                  onMouseEnter={(e) => e.currentTarget.style.color = '#5900D9'}
                  onMouseLeave={(e) => e.currentTarget.style.color = '#8133F1'}
                >
                  Create one.
                </a>
              </Typography>
            </Box>
          </Box>
          {!isSmallScreen && (
            <Box
              sx={{
                width: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                backgroundColor: '#8133F1',
                overflow: 'hidden',
              }}
            >
              <img
                src={translationGif}
                alt="Translation"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  position: 'absolute',
                }}
              />
            </Box>
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Login;


