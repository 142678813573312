import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { Home as HomeIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import NotFoundImage from '../assets/error-404.gif'; // Import the image

const NotFound = () => {
  const navigate = useNavigate();
  const isAuthenticated = sessionStorage.getItem('cid'); // Check if the user is authenticated

  const handleHomeClick = () => {
    if (isAuthenticated) {
      navigate('/dashboard'); // Navigate to the Projects page if authenticated
    } else {
      navigate('/home'); // Navigate to the Login page if not authenticated
    }
  };

  return (
    <Container style={styles.container}>
      <Box style={styles.content}>
        <img src={NotFoundImage} alt="404 Not Found" style={styles.image} />
        <Typography variant="h4" style={styles.title}>
          Page Not Found
        </Typography>
        <Typography variant="body1" style={styles.message}>
          Sorry, the page you are looking for does not exist.
        </Typography>
        <Button
          variant="contained"
          startIcon={<HomeIcon />}
          onClick={handleHomeClick}
          sx={{
            mt: 2,
            backgroundColor: '#30119E', // Set your desired background color here
            '&:hover': {
              backgroundColor: '#1565c0', // Set the hover background color here
            },
            color: '#fff',
          }}
        >
          Go to Home
        </Button>
      </Box>
    </Container>
  );
};

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    textAlign: 'center',
  },
  content: {
    maxWidth: '600px',
    width: '100%',
    padding: '20px',
    borderRadius: '15px',
    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
  },
  image: {
    width: '50%',
    height: '50%',
    marginBottom: '20px',
  },
  title: {
    fontSize: '2rem',
    color: '#333',
    marginBottom: '10px',
  },
  message: {
    fontSize: '1rem',
    color: '#555',
    marginBottom: '20px',
  },
};

export default NotFound;
