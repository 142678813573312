import React, { useState, useEffect } from 'react';
import {
  AppBar, Toolbar, Typography, IconButton, Box, Menu, MenuItem, Avatar,
  Divider, Button, Tab, Tabs, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate, useLocation } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LogoutIcon1 from '../assets/logoutticon.png';
import changepass from '../assets/changepass.png';
import logouticon from '../assets/logouticon.png';
import LockResetIcon from '@mui/icons-material/LockReset';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import LockIcon from '@mui/icons-material/Lock';
import LogoutIcon from '@mui/icons-material/Logout';
// Custom styled AppBar
const CustomAppBar = styled(AppBar)({
  backgroundColor: '#A37CF0',
  boxShadow: 'none',
  height: 40,
  justifyContent: 'center',
  position: 'fixed',
  top: 0,
  zIndex: 1100,
});

// Custom styled Toolbar
const CustomToolbar = styled(Toolbar)({
  minHeight: 40,
  justifyContent: 'space-between',
  paddingLeft: 8,
  paddingRight: 8,
  textTransform: 'none',
});

// Custom styled IconButton to fit the smaller AppBar
const CustomIconButton = styled(IconButton)({
  padding: 4,
  color: 'white',
});

// Custom styled Button
const CustomButton = styled(Button)({
  backgroundColor: '#B0B0FF',
  color: '#000',
  textTransform: 'none',
  borderRadius: '18px',
  marginLeft: '40px',
  '&:hover': {
    backgroundColor: '#7977C9',
  },
});

// UserMenuItem component
const UserMenuItem = ({ uname }) => {
  const getInitial = (name) => {
    return name ? name.charAt(0).toUpperCase() : '';
  };



  return (
    <MenuItem disabled>
      <Avatar sx={{ bgcolor: '#C0BFFB', color: 'black' }}>
        {getInitial(uname)}
      </Avatar>
      <Typography variant="body1" sx={{ marginLeft: 1, color: 'black' }}>
        {uname}
      </Typography>
    </MenuItem>
  );
};

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [uname, setUname] = useState(sessionStorage.getItem('uname') || ''); // Initialize with session storage value
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false); // No longer need loading state
  const [anchorEl, setAnchorEl] = useState(null);
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0); // State for selected tab index
  const [planAnchorEl, setPlanAnchorEl] = useState(null); // State for plan details menu
  const [role, setRole] = useState(sessionStorage.getItem('role')); // Fetch role from session storage

  useEffect(() => {
    // Update selected tab based on the current path
    switch (location.pathname) {
      case '/dashboard':
        setSelectedTab(0);
        break;
      case '/manage_members':
        setSelectedTab(role === 'admin' ? 1 : null);
        break;
      case '/dictionary':
        setSelectedTab(role === 'admin' ? 2 : 1);
        break;
      case '/translate':
        setSelectedTab(role === 'admin' ? 3 : 2);
        break;
      default:
        setSelectedTab(false);
        break;
    }
  }, [location.pathname, role]);

  const handleTabChange = (event, newValue) => {
    let path;
    switch (newValue) {
      case 0:
        path = '/dashboard';
        break;
      case 1:
        path = role === 'admin' ? '/manage_members' : '/dictionary';
        break;
      case 2:
        path = role === 'admin' ? '/dictionary' : '/translate';
        break;
      case 3:
        path = '/translate';
        break;
      default:
        return;
    }
    navigate(path);
  };


  const handleLoginClick = () => {
    setLogoutDialogOpen(true);
  };

  const handleProfileMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutConfirm = () => {
    sessionStorage.clear();
    setLogoutDialogOpen(false);
    navigate('/');
  };

  const handleLogoutCancel = () => {
    setLogoutDialogOpen(false);
  };

  const handlePlanMenuClick = (event) => {
    setPlanAnchorEl(event.currentTarget);
  };

  const handlePlanMenuClose = () => {
    setPlanAnchorEl(null);
  };

  const handleUpgradePlan = () => {
    navigate('/subscription');
  };

  const getInitial = (name) => {
    return name ? name.charAt(0).toUpperCase() : '';
  };

  return (
    <CustomAppBar position="static">
      <CustomToolbar>
        <Box display="flex" alignItems="center">
          <Typography variant="h6" noWrap sx={{ fontSize: { xs: '0.9rem', sm: '1rem' }, color: 'white' }}>
            Custom Translator
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" sx={{ flexGrow: 1, justifyContent: 'flex-end', paddingRight: { xs: '20px', sm: '50px' } }}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            textColor="white"
            TabIndicatorProps={{
              style: {
                backgroundColor: '#8133F1',
                height: 2.5,
                bottom: 2, // Positioning relative to the bottom of the tab
                marginBottom: 2, // Adjusts the position up (negative value)
                fontWeight: 'bold',
              },
            }}
            sx={{
              '& .MuiTabs-flexContainer': {
                '& .MuiTab-root': {
                  textTransform: 'none',
                  minWidth: 0,
                  marginLeft: { xs: '10px', sm: '20px' },
                  padding: { xs: '6px 12px', sm: '8px 16px' },
                  '&:hover': {
                    color: 'white',
                    opacity: 0.8,
                  },
                },
                '& .Mui-selected': {
                  color: 'white', // Default text color for all tabs
                  // fontWeight: 'bold', // Bold font for selected tab
                },
              },
            }}
          >
            <Tab label="Dashboard" />
            {role === 'admin' && <Tab label="Manage Members" />}
            <Tab label="Dictionary" />
            <Tab label="Translator" />
          </Tabs>
        </Box>
        <Box display="flex" alignItems="center">
          <CustomIconButton onClick={handleProfileMenuClick}>
            <Avatar sx={{ bgcolor: 'white', color: 'black', width: 20, height: 20, fontSize: 10 }}>
              {getInitial(uname)}
            </Avatar>
          </CustomIconButton>
          <Typography variant="body2" sx={{ marginLeft: 1, color: 'white' }}>
            {uname}
          </Typography>
          <CustomIconButton onClick={handlePlanMenuClick}>
            <MoreVertIcon fontSize="small" />
          </CustomIconButton>
        </Box>
      </CustomToolbar>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleProfileMenuClose}
        disableScrollLock={true}

      >
        <UserMenuItem uname={uname} />
        <Divider />

        <MenuItem onClick={() => navigate('/changepassword')} sx={{ color: '#575757' }}>
          <IconButton color="primary"
            sx={{
              color: '#575757', padding: '4px',
              '&:hover': {
                color: '#8133F1',
              },
            }}>
            <LockIcon />
          </IconButton>

          <Typography variant="body2" sx={{ color: '#424242', marginLeft: '10px', fontSize: '13px' ,'&:hover': {
                color: 'black',
              },}}>Change Password</Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLoginClick} sx={{ display: 'flex', alignItems: 'center', color: '#575757' }}>

          <IconButton color="primary"
            sx={{
              color: '#575757', padding: '4px',
              '&:hover': {
                color: '#8133F1',
              },
            }}>
            <LogoutIcon />

          </IconButton>
          <Typography variant="body2" sx={{ color: '#424242', marginLeft: '10px', fontSize: '13px' ,'&:hover': {
                color: 'black',}}}>Log Out</Typography>
        </MenuItem>
      </Menu>

      <Menu
        anchorEl={planAnchorEl}
        open={Boolean(planAnchorEl)}
        onClose={handlePlanMenuClose}
        disableScrollLock={true}
      >
        <MenuItem disabled>
          <Typography variant="body1" sx={{ color: 'black' }}>Free Plan</Typography>
        </MenuItem>
        <Divider />
        <MenuItem disabled>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography variant="body2" sx={{ color: 'black' }}>Translations</Typography>
            <Typography variant="body2" sx={{ color: 'black' }}>50</Typography>
          </Box>
        </MenuItem>
        <MenuItem disabled>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography variant="body2" sx={{ color: 'black', marginRight: '60px' }}>Dictionary Terms</Typography>
            <Typography variant="body2" sx={{ color: 'black' }}>100</Typography>
          </Box>
        </MenuItem>
        <MenuItem disabled>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography variant="body2" sx={{ color: 'black' }}>Team Members</Typography>
            <Typography variant="body2" sx={{ color: 'black' }}>4</Typography>
          </Box>
        </MenuItem>

        <Divider />


        {role !== 'member' && (
          <Box sx={{ marginLeft: '30px' }}>
            <Button
              variant="contained"
              component="label"
              autoFocus={true}
              onClick={handleUpgradePlan}
              sx={{
                textTransform: 'none',
                backgroundColor: '#8133F1',
                color: 'white',
                fontSize: '14px',
                minWidth: '106px',
                height: '36px',
                padding: '0 16px',
                '&:hover': {
                  backgroundColor: '#5900D9',
                },
                '&:focus': {
                  backgroundColor: '#8133F1',
                },
                '& .MuiButton-label': {
                  textAlign: 'center',
                },
                marginLeft: '22px',
              }}
            >
              Upgrade Plan
            </Button>
          </Box>
        )}

      </Menu>

      <Dialog
        open={logoutDialogOpen}
        onClose={handleLogoutCancel}
        disableScrollLock={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            backgroundColor: '#E7EBF8',
            padding: '8px',
            borderRadius: '3px',
            width: '280px',
            height: '150px',
            overflow: 'hidden'
          },
        }}
      >
        <DialogTitle id="alert-dialog-title" sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ flexGrow: 1, fontWeight: 'bold', fontSize: '23px' }}></Typography>
        </DialogTitle>
        <div color="primary">
          <img src={LogoutIcon1} alt="Logout" style={{ width: 30, height: 30, marginLeft: '40%', top: '20px', padding: '5px', position: 'absolute' }} /> {/* Replacing text with icon */}
        </div>

        <DialogContent sx={{ overflow: 'hidden' }}>
          <DialogContentText id="alert-dialog-description" sx={{ textAlign: 'center', fontSize: '15px', color: 'black', padding: '9px' }}>
            Do you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'flex-end' }}>
          <CustomButton onClick={handleLogoutCancel}
            sx={{
              textTransform: "none",
              minWidth: '48px',
              color: "white",
              height: '21px',
              borderRadius: '4px',
              '&:hover': {
                backgroundColor: '#5900D9',
              },
              '& .MuiButton-label': {
                textAlign: 'center',
              },
              fontSize: "11px", backgroundColor: '#8133F1'
            }}>
            No
          </CustomButton>
          <Button onClick={handleLogoutConfirm}
            sx={{
              textTransform: "none",
              minWidth: '48px',
              height: '21px',
              borderRadius: '4px',
              color: "white",
              '&:hover': {
                backgroundColor: '#5900D9',
              },
              '& .MuiButton-label': {
                textAlign: 'center',
              },
              fontSize: "11px", backgroundColor: '#8133F1'
            }}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </CustomAppBar>
  );
};

export default Navbar;