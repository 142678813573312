import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box, Link, useMediaQuery } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Link as RouterLink } from 'react-router-dom';
import image from '../assets/ForgotPass.png';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
//require('dotenv').config();


const theme = createTheme();

const ForgotPassword = () => {
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [message, setMessage] = useState('');
  const [emailError, setEmailError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address.');
      setError(true);
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/forgot-password`, { email });
      if (response.status === 200) {
        setMessage(response.data.message);
        const data = {
          otp: response.data.otp,
          uid: response.data.uid,
          email: email
        };
        navigate('/Verification', { state: data });
      } else {
        setMessage('Email not found.');
        setEmail('');
        setError(true);
      }
    } catch (error) {
      setMessage('Email not found.');
      setEmail('');
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailError('');
    setError(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: '100%', height: isSmallScreen ? '20vh' : '30vh', backgroundColor: ' #8133F1', display: 'flex' }}>
      </Box>
      <Container component="main" sx={{ display: 'flex', justifyContent: 'center' }}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: -7,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            border: '2px',
            borderRadius: '10px',
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            padding: '20px',
            width: isSmallScreen ? '90vw' : '30vw',
            height: 'fit-content'
          }}
        >
          <Typography sx={{ display: 'flex', justifyContent: 'center', mt: -1 }}>
            <img src={image} alt="Image" style={{ width: isSmallScreen ? '20vw' : '6vw', height: isSmallScreen ? '10vh' : '12vh' }} />
          </Typography>
          <Typography component="h1" variant="h5">
            Forgot Password
          </Typography>
          <Typography variant="body2" color="textSecondary" align="center" sx={{ mt: 3 }}>
            Enter your email and we'll send you a verification code to reset your password.
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, width: isSmallScreen ? '80vw' : '25vw', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <TextField
              margin="normal"
              required
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={handleEmailChange}
              error={!!emailError}
              helperText={emailError}
              sx={{
                width: '90%',
                '& .MuiInputLabel-asterisk': {
                  display: 'none', // Hides the asterisk
                },
                mb: 2,
                '& .MuiOutlinedInput-root': {
                  height: '40px', // Set a fixed height
                  '& fieldset': {
                    borderColor: 'rgba(0, 0, 0, 0.38)', // grey 600
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(0, 0, 0, 0.62)', // grey 800
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'rgba(0, 0, 0, 0.62)', // grey 800
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'rgba(0, 0, 0, 0.38)', // Default label color (grey 600)
                  transform: 'translate(14px, 9px) scale(1)',
                  '&.Mui-focused, &.MuiFormLabel-filled': {
                    transform: 'translate(14px, -9px) scale(0.75)',
                    color: 'rgba(0, 0, 0, 0.62)', // Focused label color (grey 800)
                  },
                },
                '& .MuiInputBase-input': {
                  padding: '8px 14px', // Adjust padding to center the text vertically
                },
              }}
            />
            {message && (
              <Typography variant="body2" color={error ? 'error' : 'textPrimary'}>
                {message}
              </Typography>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  mt: 2, mb: 2, borderRadius: '5px',
                  backgroundColor: '#8133F1',

                  fontSize: '14px',  // Minimum text size requirement
                  minWidth: '106px',
                  height: '36px',
                  padding: '0 16px',  // Minimum padding left-right
                  '&:hover': {
                    backgroundColor: '#5900D9',
                  },
                  '& .MuiButton-label': {
                    textAlign: 'center',
                  }, width: isSmallScreen ? '100%' : '90%', textTransform: "none"
                }}
                disabled={loading}
              >
                {loading ? 'Sending...' : 'Send'}
              </Button>
            </Box>
            <Box sx={{ width: '100%', textAlign: 'center', mt: 1, mb: 1 }}>
              <Link
                component={RouterLink}
                to="/login"
                variant="body2"
                sx={{
                  color: "#8133F1", // Default color
                  textDecoration: 'none',
                  borderBottom: '1px solid #8133F1',
                  '&:hover': {
                    color: "#5900D9", // Color on hover
                    borderBottomColor: "#5900D9",
                  },
                }}
              >
                Back to Login
              </Link>
            </Box>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default ForgotPassword;
