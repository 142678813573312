import React, { useState } from 'react';
import { Container, Box, Typography, TextField, Button, Link, IconButton, InputAdornment, useMediaQuery } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import image from '../assets/PassReset.jpg';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {  Cancel } from '@mui/icons-material';
import axios from 'axios';


//require('dotenv').config();

const theme = createTheme();

const ResetPasswordPage = () => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [passwordErrors, setPasswordErrors] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const { uid } = location.state || {};

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
  const validatePassword = (password) => {
    const errors = [];
    if (password.length < 8) {
      errors.push('Must be at least 8 characters!');
    }
    if (!/\d/.test(password)) {
      errors.push('Must contain at least 1 number!');
    }
    if (!/[A-Z]/.test(password)) {
      errors.push('Must contain at least 1 in Capital Case!');
    }
    if (!/[a-z]/.test(password)) {
      errors.push('Must contain at least 1 Letter in Small Case!');
    }
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      errors.push('Must contain at least 1 Special Character!');
    }
    return errors;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');

    if (!newPassword || !confirmPassword) {
      setError('Please fill in all fields');
      return;
    }

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    const passwordErrors = validatePassword(newPassword);
    if (passwordErrors.length > 0) {
      setPasswordErrors(passwordErrors);
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/reset-password`, { uid, password: newPassword });
      if (response.status === 200) {
        setSuccessMessage('Password Changed Successfully');
        setTimeout(() => {
          navigate('/');
        }, 2000);
      } else {
        setError('Failed to reset password. Please try again.');
      }
    } catch (error) {
      setError('Something went wrong. Please try again.');
      console.error('Error resetting password:', error);
    }
  };
  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setNewPassword(password);
    setPasswordErrors(validatePassword(password));
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: '100%', height: isSmallScreen ? '20vh' : '30vh', backgroundColor: '#8133F1', display: 'flex' }} />
      <Container component="main" sx={{ display: 'flex', justifyContent: 'center' }}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: -7,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            border: '2px',
            borderRadius: '10px',
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            width: isSmallScreen ? '90vw' : '30vw',
            height: 'fit-content',
            padding: isSmallScreen ? '20px' : '20px',
          }}
        >
          <Typography sx={{ display: 'flex', justifyContent: 'center', mt: -1 }}>
            <img src={image} alt="Image" style={{ width: isSmallScreen ? '20vw' : '5vw', height: isSmallScreen ? '10vh' : '10vh' }} />
          </Typography>
          <Typography variant="h5" component="h1" gutterBottom sx={{ mt: 1 }}>
            Reset Password
          </Typography>
          <Typography sx={{ textAlign: 'center', padding: isSmallScreen ? '0 10px' : '0', fontSize: '12.5px' }}>
            Password must be 8 characters long and must contain both numbers and symbols.
          </Typography>
          <TextField
            label="New Password"
            variant="outlined"
            type={showPassword ? 'text' : 'password'}
            margin="normal"
            sx={{
              width: '80%',
              mb: 1,
              '& .MuiOutlinedInput-root': {
                height: '40px',
                '& fieldset': {
                  borderColor: 'rgba(0, 0, 0, 0.38)',
                },
                '&:hover fieldset': {
                  borderColor: 'rgba(0, 0, 0, 0.62)',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'rgba(0, 0, 0, 0.62)',
                },
              },
              '& .MuiInputLabel-root': {
                color: 'rgba(0, 0, 0, 0.38)',
                transform: 'translate(14px, 9px) scale(1)',
                '&.Mui-focused, &.MuiFormLabel-filled': {
                  transform: 'translate(14px, -9px) scale(0.75)',
                  color: 'rgba(0, 0, 0, 0.62)',
                },
              },
              '& .MuiInputBase-input': {
                padding: '8px 14px',
              },
            }}
            value={newPassword}
            onChange={handlePasswordChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          {passwordErrors.length > 0 && (
            <Box sx={{ width: '80%', mt: 1, mb: 1 }}>
              {passwordErrors.map((error, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
                  <Cancel color="error" fontSize="small" sx={{ mr: 1 }} />
                  <Typography variant="caption" color="error">
                    {error}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}

          <TextField
            label="Confirm Password"
            variant="outlined"
            type={showConfirmPassword ? 'text' : 'password'}
            margin="normal"
            sx={{
              width: '80%',

              mb: 2,
              '& .MuiOutlinedInput-root': {
                height: '40px', // Set a fixed height
                '& fieldset': {
                  borderColor: 'rgba(0, 0, 0, 0.38)', // grey 600
                },
                '&:hover fieldset': {
                  borderColor: 'rgba(0, 0, 0, 0.62)', // grey 800
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'rgba(0, 0, 0, 0.62)', // grey 800
                },
              },

              '& .MuiInputLabel-root': {
                color: 'rgba(0, 0, 0, 0.38)', // Default label color (grey 600)
                transform: 'translate(14px, 9px) scale(1)',
                '&.Mui-focused, &.MuiFormLabel-filled': {
                  transform: 'translate(14px, -9px) scale(0.75)',
                  color: 'rgba(0, 0, 0, 0.62)', // Focused label color (grey 800)
                },
              },
              '& .MuiInputBase-input': {
                padding: '8px 14px', // Adjust padding to center the text vertically
              },


            }}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle confirm password visibility"
                    onClick={handleClickShowConfirmPassword}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          {error && (
            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
              {error}
            </Typography>
          )}
          {successMessage && (
            <Typography variant="body2" color="success" sx={{ mt: 1 }}>
              {successMessage}
            </Typography>
          )}
          <Button
            variant="contained"
            color="primary"
            sx={{
              mt: 2, mb: 2, borderRadius: '5px',
              backgroundColor: '#8133F1',

              fontSize: '14px',  // Minimum text size requirement
              minWidth: '106px',
              height: '36px',
              padding: '0 16px',  // Minimum padding left-right
              '&:hover': {
                backgroundColor: '#5900D9',
              }, width: isSmallScreen ? '100%' : '80%', textTransform: 'none'
            }}
            onClick={handleSubmit}
          >
            Save Changes
          </Button>
          {!successMessage && (
            <Box sx={{ width: '100%', textAlign: 'center' }}>
              <Link component={RouterLink} to="/login"
                sx={{
                  color: "#8133F1", // Default color
                  textDecoration: 'none',
                  borderBottom: '1px solid #8133F1',
                  '&:hover': {
                    color: "#5900D9", // Color on hover
                    borderBottomColor: "#5900D9",
                  },
                }}

                variant="body2">
                Back to Login
              </Link>
            </Box>
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default ResetPasswordPage;