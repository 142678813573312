import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import FileTranslate from './components/Filetranslate';
import Subscription from './components/Subscription';
import Verification from './components/Verification.js';
import Dashboard from './components/dashboard.js';
import Secondary_Nav from './components/Secondary_Nav.js';
import Login from './components/Login.js';
import Register from './components/Register.js';
import PrivateRoute from './components/protectedRoute.js';
import Home from './components/Home.js';
import ManageMember from './components/Manage_Members.js';
import Dictionary from './components/Dictionary.js';
import Notfound from './components/Notfound.js';
import ForgotPassword from './components/ForgotPassword.js';
import PasswordReset from './components/PasswordReset.js';
// import GuideMe from './components/GuideMe.js';
// import WordCloud from './components/WorldCloud.js';
import ChangePassword from './components/ChangePassword.js';
import { makeStyles } from '@mui/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '6px',
      height: '4px',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '2px',
    },
    '*::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#555',
    },
  },
}));


const ProtectedRoute = ({ children, allowedRoles }) => {
  const role = sessionStorage.getItem('role');

  if (!role) {
    // Role not found in session, redirect to login
    return <Navigate to="/login" />;
  }

  if (allowedRoles.includes(role)) {
    // User has required role, allow access
    return children;
  } else {
    // User does not have required role, redirect to dashboard or home
    return <Navigate to="/dashboard" />;
  }
};

const App = () => {
  useStyles();

  const theme = createTheme({
    // your theme settings
  });
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        {/* <Route path="/GuideMe" element={<GuideMe />} />
        <Route path="/World" element={<WordCloud/>}/> */}

        <Route path="/subscription" element={<ProtectedRoute allowedRoles={['admin', 'personal-user']}>
          <Subscription />
        </ProtectedRoute>} />

        <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
        <Route path="/dictionary" element={<PrivateRoute><Dictionary /></PrivateRoute>} />

        <Route path="/manage_members" element={<PrivateRoute allowedRoles={['admin']}>
          <ManageMember />
        </PrivateRoute>} />
        <Route path="/translate" element={<PrivateRoute><FileTranslate /></PrivateRoute>} />


        <Route path="/secondary_nav" element={<Secondary_Nav />} />
        <Route path="/verification" element={<Verification />} />
        <Route path="/passwordreset" element={<PasswordReset />} />
        <Route path="/changepassword" element={<ChangePassword />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />

        <Route path="*" element={<Notfound />} />

      </Routes>
    </Router>
    </ThemeProvider>
  );
};

export default App;
