import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Paper,
  Typography,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Modal,
} from '@mui/material';
import { styled, useTheme, useMediaQuery, textAlign } from '@mui/system';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import CloseIcon from '@mui/icons-material/Close';
import Navbar from './Navbar';
import Tooltip from '@mui/material/Tooltip';
import Alert from '@mui/material/Alert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import { useNavigate, useLocation } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddTermForm from './AddTermForm';
import axios from 'axios';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Search as SearchIcon, Edit as EditIcon, Delete as DeleteIcon, AddCircle } from '@mui/icons-material';
import Papa from 'papaparse';
import illustration from '../assets/paper.png';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#C0BFFB',
  fontWeight: '500',
  height: '14px',
  padding: '3px',
  fontSize: '0.8rem',
  border: 'none',
  minWidth: '80px',
  textAlign: 'center',

}));

const BodyTableCell = styled(TableCell)(({ theme }) => ({
  height: '14px',
  padding: '3px',
  fontSize: '0.8rem',
  border: 'none',
  minWidth: '80px',
  textAlign: 'center',
}));

const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#30119E',
  color: '#FFFFFF',
  '&:hover': {
    backgroundColor: 'primary',
  },
}));


const MainContent = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  marginTop: '20px',
  overflow: 'hidden',
  marginLeft: '40px',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
    marginLeft: 0,
  },
}));

const CustomTableHead = styled(TableHead)(({ theme }) => ({
  '& th': {
    backgroundColor: '#A37CF0',
    padding: 0,
    height: '14px',
    textAlign: 'center',
    color: '#FFFFFF',
  },
  '& th:last-child': {
    textAlign: 'center',

  },
}));

const CustomTableRow = styled(TableRow)(({ theme }) => ({
  borderBottom: '1px solid #E1E1E1',
}));

const Overlay = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 9999,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backdropFilter: 'blur(5px)',
}));


const DeleteConfirmationModal = ({ open, onClose, onDelete }) => (
  <Modal
    open={open}
    onClose={onClose}
    aria-labelledby="delete-confirmation-modal"
    aria-describedby="modal-to-confirm-deletion"
  >
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '300px',
        backgroundColor: '#fff',
        borderRadius: '10px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
        padding: '20px',
        textAlign: 'center',
      }}
    >
      <Typography variant="h6" component="h2" fontSize="15px" sx={{ mb: 2 }}>
        Are you sure you want to delete this term?
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'right', gap: 1 }}>
        <CustomButton variant="contained" color="primary" onClick={onClose}
          sx={{
            textTransform: "none",
            minWidth: '48px',
            height: '21px',
            '&:hover': {
              backgroundColor: '#5900D9',
            },
            '& .MuiButton-label': {
              textAlign: 'center',
            },
            // width: "60px",
            // height: "30px",
            fontSize: "11px",
            backgroundColor: '#8133F1',
          }}>
          Cancel
        </CustomButton>
        <CustomButton variant="contained" color="primary" onClick={onDelete}
          sx={{
            textTransform: "none",
            minWidth: '48px',
            height: '21px',
            '&:hover': {
              backgroundColor: '#5900D9',
            },
            '& .MuiButton-label': {
              textAlign: 'center',
            },
            // width: "60px",
            // height: "30px",
            fontSize: "11px",
            backgroundColor: '#8133F1',
          }}>
          Delete
        </CustomButton>

      </Box>
    </Box>
  </Modal>
);


function Dictionary() {
  const uid = sessionStorage.getItem('uid');
  const cid = sessionStorage.getItem('cid');
  const [showAddTermForm, setShowAddTermForm] = useState(false);
  const [profanityTerms, setProfanityTerms] = useState([]);
  const [terms, setTerms] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const navigate = useNavigate();
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const location = useLocation();
  const [hasPermissions, setHasPermissions] = useState(false);
  const [assets, setAssets] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false); // State for delete modal visibility
  const [termToDelete, setTermToDelete] = useState(null); // State for the term to be deleted

  const [filteredAssets, setFilteredAssets] = useState([]); // State for filtered assets
  const [reload, setReload] = useState(false);
  const [search, setSearch] = useState('');
  const [fileSelected, setFileSelected] = useState(false); // State to track if a file is selected
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [duplicateTerms, setDuplicateTerms] = useState([]);
  const [selectedTerm, setSelectedTerm] = useState(null); // State for selected term to edit
  const [showEditModal, setShowEditModal] = useState(false); // State for edit modal visibility

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));
  const handleNavigate = () => {
    navigate('/translate');
  };
  const handleSearchChange = (event) => {
    const searchTerm = event.target.value;
    setSearch(searchTerm);
    filterAssets(searchTerm);
  };

  useEffect(() => {
    fetchAssets();
    const permissions = sessionStorage.getItem('permissions');
    setHasPermissions(permissions === 'true');
  }, [reload]);

  useEffect(() => {
    console.log('Assets:', assets);
  }, [assets]);

  const fetchAssets = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/fetch-terms`, { uid });
      console.log('Fetched response:', response.data);
      if (response.data.success && response.data.terms) {
        const transformedTerms = response.data.terms.map(term => ({
          term: term.terms || 'N/A',
          createdBy: term.created_by || 'N/A',
          Date: new Date(term.created_at).toLocaleDateString(),
          dictid: term.dictid,
          pdictid: term.pdictid,
        }));

        setAssets(transformedTerms);
      } else {
        setAssets([]);
      }
    } catch (error) {
      console.error('Error fetching assets:', error);
      setAssets([]);
    }
  };

  const handleAddTermClick = () => {
    setShowAddTermForm(true);
  };

  const handleTermAdded = async () => {
    try {
      await fetchAssets(); // Refresh the list of terms after adding a new term
      showSnackbar('Term added successfully', 'success');

    } catch (error) {
      console.error('Error adding term:', error);
      showSnackbar('Failed to add term', 'error');
    } finally {
      setShowAddTermForm(false);
    }
  };

  const handleFormClose = () => {
    setShowAddTermForm(false);
    fetchAssets();
  };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const SnackbarContentWrapper = (
    <SnackbarContent
      message={snackbarMessage}
      action={
        <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    />
  );

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type === 'text/csv' || file.name.endsWith('.csv')) {
        const formData = new FormData();
        formData.append('uid', uid);
        formData.append('file', file);

        try {
          const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/dictionary/import`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

          if (response.data.success) {
            showSnackbar(`Added ${response.data.newTermsAdded} new terms.`);
            setReload(prevReload => !prevReload);

            // Check for duplicate terms
            // if (response.data.duplicateTerms && response.data.duplicateTerms.length > 0) {
            if (response.data.duplicateTerms.length > 0 || response.data.profanityTerms.length > 0) {
              // console.log(profanityTerms)
              setDuplicateTerms(response.data.duplicateTerms);
              setProfanityTerms(response.data.profanityTerms);
              setShowDuplicateModal(true);

            } else {
              setDuplicateTerms([]);
              setProfanityTerms([]);
              setShowDuplicateModal(false);
            }
          } else {
            setDuplicateTerms(response.data.duplicateTerms || []);
            setProfanityTerms(response.data.profanityTerms || []);
            setShowDuplicateModal(true);
            console.error('Failed to add terms');
          }
        } catch (error) {
          console.error('Error uploading file:', error);
          setDuplicateTerms([]);
          setProfanityTerms([]);
          setShowDuplicateModal(true);
        }
      } else {
        showSnackbar('Invalid file type. Please select a CSV file.', 'error');

      }
    } else {
      console.error('No file selected');
    }
  };



  const filterAssets = (searchTerm) => {
    if (!searchTerm) {
      setFilteredAssets([]);
    } else {
      const filtered = assets.filter(asset =>
        asset.term.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredAssets(filtered);
    }
  };


  const EditTermModal = ({ open, onClose, term, onTermEdited }) => {
    const [editedTerm, setEditedTerm] = useState(null);

    useEffect(() => {
      if (term) {
        setEditedTerm(term);
      }
    }, [term]);

    const handleEditTerm = async () => {
      if (!editedTerm.term.trim()) {
        showSnackbar("Please enter a term", 'error');
        return;
      }
      try {
        let editParams = {
          uid: sessionStorage.getItem('uid'),
          newTerm: editedTerm.term,
        };

        // Check if the term has dictid or pdictid
        if (editedTerm.dictid !== undefined) {
          editParams.dictid = editedTerm.dictid;
        } else if (editedTerm.pdictid !== undefined) {
          editParams.pdictid = editedTerm.pdictid;
        } else {
          throw new Error('Neither dictid nor pdictid found for the term');
        }

        const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/dictionary/editTerm`, editParams);

        if (response.data.success) {
          onTermEdited(editedTerm);
          onClose();
        } else {
          console.error('Failed to update term:', response.data.message);
        }
      } catch (error) {
        console.error('Error updating term:', error);
      }
    };

    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setEditedTerm({ ...editedTerm, [name]: value });
    };

    if (!term) return null; // Render nothing if term is null

    return (
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="edit-term-modal"
        aria-describedby="modal-to-edit-term"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '300px',
            backgroundColor: '#e7ebf8',
            borderRadius: '5px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
            padding: '20px',
            textAlign: 'left',
            position: 'relative',
          }}
        >
          <Typography sx={{ mb: 2, fontSize: "15px", fontWeight: "bold" }}>
            Edit Term
          </Typography>
          {/* <TextField
            name="term"
            value={editedTerm ? editedTerm.term : ''}
            onChange={handleInputChange}
            fullWidth
            required
            InputLabelProps={{ shrink: false }}
            sx={{ marginBottom: '20px', backgroundColor: 'white' }}
          /> */}

          <TextField
            name="term"
            value={editedTerm ? editedTerm.term : ''}
            onChange={handleInputChange}
            fullWidth
            required
            InputLabelProps={{ shrink: false }}
            sx={{
              mb: 2,
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'rgba(0, 0, 0, 0.38)', // grey 600
                },
                '&:hover fieldset': {
                  borderColor: 'rgba(0, 0, 0, 0.62)', // grey 800
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'rgba(0, 0, 0, 0.62)', // grey 800
                },
              },
            }}
            InputProps={{
              style: {
                backgroundColor: 'white',
                fontFamily: 'Poppins, sans-serif',
                fontSize: "13px",
                height: '2.2rem',
              },
              inputProps: {
                style: {
                  paddingTop: '20px',
                },
              },
            }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'right', gap: 1 }}>
            <CustomButton variant="contained" color="primary" onClick={onClose}
              sx={{
                textTransform: "none",
                minWidth: '48px',
                height: '21px',
                '&:hover': {
                  backgroundColor: '#5900D9',
                },
                '& .MuiButton-label': {
                  textAlign: 'center',
                },
                // width: "60px",
                // height: "30px",
                fontSize: "11px",
                backgroundColor: '#8133F1',
              }}
            >
              Cancel
            </CustomButton>
            <CustomButton variant="contained"
              sx={{
                textTransform: "none",
                minWidth: '48px',
                height: '21px',
                '&:hover': {
                  backgroundColor: '#5900D9',
                },
                '& .MuiButton-label': {
                  textAlign: 'center',
                },
                // width: "60px",
                // height: "30px",
                fontSize: "11px",
                backgroundColor: '#8133F1',
              }}
              onClick={handleEditTerm}>
              Save
            </CustomButton>


          </Box>
        </Box>
      </Modal>
    );
  };




  const handleEditClick = (term) => {
    setSelectedTerm(term);
    setShowEditModal(true);
  };




  const handleTermEdited = (editedTerm) => {
    const updatedTerms = assets.map((t) => {
      if (t.dictid && t.dictid === editedTerm.dictid) {
        return { ...t, term: editedTerm.term };
      } else if (t.pdictid && t.pdictid === editedTerm.pdictid) {
        return { ...t, term: editedTerm.term };
      }
      return t;
    });
    setAssets(updatedTerms);
    showSnackbar('Term updated successfully');
  };


  const handleDeleteClick = (term) => {
    setTermToDelete(term);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      let deleteParams = {
        uid,
      };

      if (termToDelete.dictid !== undefined) {
        deleteParams.dictid = termToDelete.dictid;
      } else if (termToDelete.pdictid !== undefined) {
        deleteParams.pdictid = termToDelete.pdictid;
      } else {
        throw new Error('Neither dictid nor pdictid found for the term');
      }

      const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/dictionary/deleteTerm`, {
        params: deleteParams
      });

      if (response.data.success) {
        showSnackbar('Term deleted successfully', 'success');
        // Update the state to remove the deleted term
        setAssets(prevAssets => prevAssets.filter((asset) => {
          if (termToDelete.dictid !== undefined) {
            return asset.dictid !== termToDelete.dictid;
          } else {
            return asset.pdictid !== termToDelete.pdictid;
          }
        }));
        setShowDeleteModal(false);
      } else {
        showSnackbar('Failed to delete term', 'error');
      }
    } catch (error) {
      console.error('Error deleting term:', error);
      // alert('Failed to delete term');
    }
  };



  const handleDeleteClose = () => {
    setShowDeleteModal(false);
  };

  const handleExportCSV = async () => {
    if (assets.length === 0) {
      showSnackbar('No terms available to export');
      return;
    }
    try {
      console.log('Starting CSV export...');
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/dictionary/export`,
        { cid, uid },
        { responseType: 'blob' }
      );

      console.log('Response received:', response);

      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'dictionary_terms.csv');
      document.body.appendChild(link);

      console.log('Triggering download...');
      link.click();

      console.log('Cleaning up...');
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      console.log('Export completed successfully');
      // showSnackbar('CSV exported successfully');
    } catch (error) {
      console.error('Error exporting CSV:', error);
      showSnackbar(`Failed to export CSV`, 'error');
    } finally {
      console.log('Export process finished');
    }
  };

  // const DuplicateTermsModal = ({ open, onClose, terms }) => (
  //   <Modal
  //     open={open}
  //     onClose={onClose}
  //     aria-labelledby="duplicate-terms-modal"
  //     aria-describedby="modal-to-display-duplicate-terms"
  //   >
  //     <Box
  //       sx={{
  //         position: 'absolute',
  //         top: '50%',
  //         left: '50%',
  //         transform: 'translate(-50%, -50%)',
  //         width: '400px',
  //         backgroundColor: '#fff',
  //         borderRadius: '10px',
  //         boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
  //         padding: '20px',
  //         textAlign: 'center',
  //       }}
  //     >
  //       <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
  //         Duplicate Terms Found
  //       </Typography>
  //       <Box sx={{ maxHeight: '300px', overflowY: 'auto', marginBottom: '20px' }}>
  //         {terms.length > 0 ? (
  //           <>
  //             <Typography variant="body1" sx={{ marginBottom: '10px' }}>
  //               The following terms already exist in the dictionary:
  //             </Typography>
  //             {terms.map((term, index) => (
  //               <Typography key={index} variant="body2">
  //                 {term}
  //               </Typography>
  //             ))}
  //           </>
  //         ) : (
  //           <Typography variant="body1">Unable to add terms due to duplication or other errors.</Typography>
  //         )}
  //       </Box>
  //       <CustomButton variant="contained" color="primary" onClick={onClose}
  //         sx={{
  //           textTransform: "none",
  //           minWidth: '48px',
  //           height: '21px',
  //           '&:hover': {
  //             backgroundColor: '#5900D9',
  //           },
  //           '& .MuiButton-label': {
  //             textAlign: 'center',
  //           },
  //           // width: "60px",
  //           // height: "30px",
  //           fontSize: "11px",
  //           backgroundColor: '#8133F1',
  //         }}>
  //         Close
  //       </CustomButton>
  //     </Box>
  //   </Modal>
  // );
  const DuplicateTermsModal = ({ open, onClose, duplicateTerms, profanityTerms }) => {
    const termsWithCauses = [
      ...duplicateTerms.map(term => ({ term, cause: 'Duplicate Entry' })),
      ...profanityTerms.map(term => ({ term, cause: 'Profanity' }))
    ];

    return (
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="invalid-entries-modal"
        aria-describedby="modal-to-display-invalid-entries"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '600px',
            backgroundColor: '#E7EBF8',
            borderRadius: '10px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
            padding: '20px',
            textAlign: 'center',
          }}
        >
          <Typography variant="h6" component="h2" sx={{ fontSize: "15px", mb: 2 }}>
            Identified Invalid Entries!
          </Typography>
          <TableContainer component={Paper} sx={{ maxHeight: '200px', overflowY: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#A37CF0" }}>
                  <TableCell sx={{ marginTop: "5px", color: "#ffffff", padding: '3px', textAlign: 'center' }}><Typography variant="body1" sx={{ fontSize: "13px" }}>Term</Typography></TableCell>
                  <TableCell sx={{ marginTop: "5px", padding: '3px', textAlign: 'center' }}><Typography variant="body1" sx={{ color: "#ffffff", fontSize: "13px" }}>Cause</Typography></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {termsWithCauses.length > 0 ? (
                  termsWithCauses.map((entry, index) => (
                    <TableRow key={index}>
                      <TableCell sx={{ padding: '1px', textAlign: 'center', marginTop: "5px", fontSize: "5px" }}><Typography variant="body2">{entry.term}</Typography></TableCell>
                      <TableCell sx={{ padding: '1px', textAlign: 'center', marginTop: "5px", fontSize: "5px" }}><Typography variant="body2">{entry.cause}</Typography></TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={2}>
                      <Typography variant="body1">No invalid entries found.</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomButton
            variant="contained"
            color="primary"
            onClick={onClose}
            sx={{
              textTransform: "none",
              minWidth: '48px',
              height: '21px',
              '&:hover': {
                backgroundColor: '#5900D9',
              },
              '& .MuiButton-label': {
                textAlign: 'center',
              },
              fontSize: "11px",
              backgroundColor: '#8133F1',
              mt: 2
            }}
          >
            Close
          </CustomButton>
        </Box>
      </Modal >
    );

  };

  return (
    <Box display="flex">
      <Navbar />
      <MainContent sx={{ paddingTop: '70px', marginTop: '1%' }}>
        <Box
          display="flex"
          alignItems="center"
          marginBottom={{ xs: '30px', sm: '50px' }}
          marginTop={{ xs: '0.5%', sm: '1%' }}
          flexDirection={{ xs: 'column', sm: 'row' }}
          marginRight="1%"
          marginLeft="-1%"
          fontSize={"30px"}

        >
          <Typography variant={isSmallScreen ? 'h4' : 'h5'} gutterBottom>
            Dictionary Terms
          </Typography>
          <Box sx={{ flexGrow: 1 }} />

          <Box
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            alignItems="center"
            justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
            marginTop={{ xs: '20px', sm: 0 }}

          >
            {hasPermissions && (
              <Button
                variant="contained"
                startIcon={<AddCircleIcon />}
                onClick={handleAddTermClick}
                sx={{
                  mr: 2,
                  backgroundColor: '#8133F1',
                  textTransform: 'none',
                  fontSize: '14px',  // Minimum text size requirement
                  minWidth: '106px',
                  height: '36px',
                  padding: '0 16px',  // Minimum padding left-right
                  '&:hover': {
                    backgroundColor: '#5900D9',
                  },
                  '& .MuiButton-label': {
                    textAlign: 'center',
                  },
                }}
              >
                Add Term
              </Button>

            )}

            {hasPermissions && (
              <Button
                variant="contained"
                component="label"
                startIcon={<UploadIcon />}
                sx={{
                  mr: 2,
                  backgroundColor: '#8133F1',
                  textTransform: 'none',
                  fontSize: '14px',  // Minimum text size requirement
                  minWidth: '106px',
                  height: '36px',
                  padding: '0 16px',  // Minimum padding left-right
                  '&:hover': {
                    backgroundColor: '#5900D9',
                  },
                  '& .MuiButton-label': {
                    textAlign: 'center',
                  },
                }}
              >
                Upload CSV
                <input
                  type="file"
                  accept=".csv"
                  hidden
                  onChange={handleFileUpload}
                />
              </Button>
            )}

            <Button
              variant="contained"
              startIcon={<DownloadIcon />}
              onClick={handleExportCSV} // Add onClick handler
              sx={{

                backgroundColor: '#8133F1',
                textTransform: 'none',
                fontSize: '14px',  // Minimum text size requirement
                minWidth: '106px',
                height: '36px',
                padding: '0 16px',  // Minimum padding left-right
                '&:hover': {
                  backgroundColor: '#5900D9',
                },
                '& .MuiButton-label': {
                  textAlign: 'center',
                },
              }}
            >
              Export CSV
            </Button>

          </Box>
        </Box>

        <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="20px" marginRight="1%" marginLeft="-1%">
          {assets.length === 0 ? (
            <Box flexGrow={1} />
          ) : (
            <Box display="flex" alignItems="center" height="2%" width="280px">
              <TextField
                variant="outlined"
                size="small"
                placeholder="Search Terms"
                value={search}
                onChange={handleSearchChange}
                sx={{
                  width: '100%',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'rgba(0, 0, 0, 0.23)',
                    },
                    '&:hover fieldset': {
                      borderColor: 'rgba(0, 0, 0, 0.23)',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#424242',
                    },
                  },
                  '& .MuiInputBase-input::placeholder': {
                    color: 'rgba(0, 0, 0, 0.6)',
                    opacity: 1,
                    fontSize: '11px',
                    fontFamily: 'Poppins, sans-serif',

                  },
                }}
                InputProps={{
                  style: { height: '33px' },
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          )}
          <Button
            variant="contained"
            onClick={handleNavigate}
            sx={{
              backgroundColor: '#8133F1',
              textTransform: 'none',
              fontSize: '14px',  // Minimum text size requirement
              minWidth: '106px',
              height: '36px',
              padding: '0 16px',  // Minimum padding left-right
              '&:hover': {
                backgroundColor: '#5900D9',
              },
              '& .MuiButton-label': {
                textAlign: 'center',
              },
            }}
          >
            Continue
          </Button>
        </Box>


        {showAddTermForm && (
          <AddTermForm
            onClose={handleFormClose}
            onTermAdded={handleTermAdded}
            fetchAssets={fetchAssets}
            assets={assets}
            uid={uid}
            cid={cid}
          />
        )}


        {assets.length === 0 ? (
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ marginTop: '100px' }}  >
            <img src={illustration} alt="No translations available" style={{ maxWidth: '6%', height: 'auto' }} />
            <Typography variant="h6" color="textSecondary" align="center" fontSize="15px" sx={{ mt: 2 }}>
              Currently you have no terms
            </Typography>
          </Box>
        ) : search && filteredAssets.length === 0 ? (
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ marginTop: '100px' }}  >
            <img src={illustration} alt="No matching terms" style={{ maxWidth: '6%', height: 'auto' }} />
            <Typography variant="h6" color="textSecondary" align="center" fontSize="15px" sx={{ mt: 2 }}>
              No terms matching your search
            </Typography>
          </Box>
        ) : (
          <TableContainer component={Paper} sx={{
            borderRadius: '5px',
            marginRight: '4%',
            marginLeft: '-1%',
          }}>
            <Table sx={{
              minWidth: 650,
              '& .MuiTableCell-root': {
                borderBottom: '1px solid rgba(0, 0, 0, 0.3)',
              },
            }} >
              <CustomTableHead>
                <TableRow>
                  <StyledTableCell>Terms</StyledTableCell>
                  <StyledTableCell>Created By</StyledTableCell>
                  <StyledTableCell>Date</StyledTableCell>
                  {hasPermissions && <StyledTableCell>Actions</StyledTableCell>}
                </TableRow>
              </CustomTableHead>
              <TableBody>
                {(filteredAssets.length > 0 ? filteredAssets : assets).map((asset, index) => (
                  <CustomTableRow key={index}>
                    <BodyTableCell>{asset.term}</BodyTableCell>
                    <BodyTableCell>{asset.createdBy}</BodyTableCell>
                    <BodyTableCell>{asset.Date}</BodyTableCell>
                    {hasPermissions && (
                      <BodyTableCell>
                        <Box display="flex" justifyContent="center">
                          <Tooltip
                            title="Edit Term"
                            leaveDelay={0}
                            disableInteractive
                          >
                            <IconButton
                              aria-label="edit"
                              onClick={() => handleEditClick(asset)}
                              sx={{
                                padding: '2px',
                                marginRight: '10px',
                                color: '#575757',
                                '&:hover': {
                                  color: '#8133F1',
                                },
                              }}
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>

                          <Tooltip
                            title="Delete Term"
                            leaveDelay={0}
                            disableInteractive
                          >
                            <IconButton
                              aria-label="delete"
                              onClick={() => handleDeleteClick(asset)}
                              sx={{
                                padding: '2px', marginRight: "5%", color: '#575757',
                                '&:hover': {
                                  color: 'red', // Change color to blue on hover
                                },
                              }}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </BodyTableCell>
                    )}
                  </CustomTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <EditTermModal
          open={showEditModal}
          onClose={() => setShowEditModal(false)}
          term={selectedTerm}
          onTermEdited={handleTermEdited}
        />
        <DeleteConfirmationModal
          open={showDeleteModal}
          onClose={handleDeleteClose}
          onDelete={handleConfirmDelete}
        />

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setSnackbarOpen(false)}
            severity={snackbarSeverity}
            sx={
              snackbarSeverity === 'success'
                ? {
                  backgroundColor: '#F8F8F8',
                  color: '#878585',
                  border: '1px solid #0DC42B',
                  borderRadius: '4px',
                  boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.3)',
                }
                : {
                  backgroundColor: '#F8F8F8',
                  color: '#878585',
                  border: '1px solid red',
                  borderRadius: '4px',
                  boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.3)',
                }
            }
            iconMapping={{
              success: <CheckCircleIcon fontSize="inherit" style={{ color: '#16D835' }} />,
              error: <ErrorIcon fontSize="inherit" style={{ color: 'red' }} />,
            }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <DuplicateTermsModal
          open={showDuplicateModal}
          onClose={() => setShowDuplicateModal(false)}
          duplicateTerms={duplicateTerms}
          profanityTerms={profanityTerms}

        />

      </MainContent>
    </Box>
  );
}
export default Dictionary;