import React from 'react';
import { Route, Navigate } from 'react-router-dom';

const PrivateRoute = ({ children, ...rest }) => {
  let cid = sessionStorage.getItem('cid');
  let uid = sessionStorage.getItem('uid');

  // Check both cid and uid before allowing access
  if (cid && uid) {
    return children;
  } else {
    return <Navigate to="/" replace />;
  }
};

export default PrivateRoute;
