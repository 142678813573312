import React, { useState, useEffect, useCallback } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  IconButton, TextField, InputAdornment, Button, Box, Typography,
  FormControl, Select, MenuItem, Grid, useMediaQuery, Dialog, DialogTitle,
  DialogContent, DialogContentText, DialogActions
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import styled from '@emotion/styled';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ClearIcon from '@mui/icons-material/Clear';
import axios from 'axios';
import Navbar from './Navbar';
import SecondaryNavbar from './Secondary_Nav';
import Texttranslate from './Texttranslate';
import illustration from '../assets/paper.png';
import { Snackbar, Alert } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import Tooltip from '@mui/material/Tooltip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

const StyledTableRow = styled(TableRow)`
  height: 30px;
  & > td {
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

const getUidAndCidFromSessionStorage = () => {
  return {
    uid: sessionStorage.getItem('uid') || '',
    cid: sessionStorage.getItem('cid') || ''
  };
};

const Filetranslate = () => {
  // Load the active tab index from localStorage, defaulting to 0 if not set
  const [activeTab, setActiveTab] = useState(() => {
    const savedTab = localStorage.getItem('activeTab');
    return savedTab !== null ? parseInt(savedTab, 10) : 0;
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [fileUploaded, setFileUploaded] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [targetLanguage, setTargetLanguage] = useState('');
  const [tableData, setTableData] = useState([]);
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const [showFileUploadGrid, setShowFileUploadGrid] = useState(false);
  const [languagesList, setLanguagesList] = useState([]);
  const [translations, setTranslations] = useState([]);
  const [{ uid, cid }, setUidAndCid] = useState(getUidAndCidFromSessionStorage());
  const [userPermissions, setUserPermissions] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteTransId, setDeleteTransId] = useState(null);
  const [previewContent, setPreviewContent] = useState('');
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
  const [sourceLanguage, setSourceLanguage] = useState('auto');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [openDownloadSnackbar, setOpenDownloadSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openDeleteSnackbar, setOpenDeleteSnackbar] = useState(false);
  const showErrorSnackbar = (message) => {
    setErrorMessage(message);
    setOpenErrorSnackbar(true);
  };
  useEffect(() => {
    getLanguagesList();
    fetchTranslations();
    const permissions = sessionStorage.getItem('permissions') === 'true';
    setUserPermissions(permissions);
  }, [uid, cid]);

  useEffect(() => {
    // Save the active tab index to localStorage whenever it changes
    localStorage.setItem('activeTab', activeTab);
  }, [activeTab]);
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (!file) {
      return;
    }
    if (file.type !== "text/plain") {
      showErrorSnackbar("Please upload only text files (TXT files).");
      return;
    }
    setSelectedFile(file);
    setFileUploaded(true);
    setShowFileUploadGrid(true);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'text/plain',
    multiple: false
  });


  const fetchTranslations = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/translation/history`, { uid, cid });
      // Sort the translations by date in descending order
      const sortedTranslations = response.data.translation_details.sort((a, b) =>
        new Date(b.created_at) - new Date(a.created_at)
      );
      setTranslations({ translation_details: sortedTranslations });
    } catch (error) {
      console.error('Error fetching translations:', error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  const getLanguagesList = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/translation/sourcelanguages`);
      const languages = response.data.source_languages;
      if (!languages) {
        throw new Error('No languages data found');
      }
      const languagesArray = Object.keys(languages).map(code => ({
        code,
        name: languages[code].name,
      }));
      setLanguagesList(languagesArray);
    } catch (error) {
      console.error('Error fetching languages:', error);
      showErrorSnackbar('Failed to fetch languages. Please try again.');
    }
  };
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    if (file.type !== "text/plain") {
      showErrorSnackbar("Please upload only text files (TXT files).");
      return;
    }
    setSelectedFile(file);
    setFileUploaded(true);
    setShowFileUploadGrid(true);
  };

  const handleSourceLanguageChange = (event) => {
    setSourceLanguage(event.target.value);
  };

  const handleTargetLanguageChange = (event) => {
    setTargetLanguage(event.target.value);
  };
  const getLanguageName = (code) => {
    if (code === 'auto') return 'Auto Detect';
    const language = languagesList.find(lang => lang.code === code);
    return language ? language.name : code;
  };


  const handleTranslate = async () => {
    if (selectedFile && targetLanguage && uid && cid) {
      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('targetLang', targetLanguage);
      formData.append('uid', uid);
      formData.append('cid', cid);

      if (sourceLanguage !== 'auto') {
        formData.append('sourceLang', sourceLanguage);
      }

      try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/translation/translateFile`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        const { translatedText, sourceLang, wordCount } = response.data;

        const sourceLangName = getLanguageName(sourceLang);

        const translatedData = {
          transid: Date.now(), // Generate a temporary unique ID
          file_name: selectedFile.name,
          status: 'Completed',
          sourceLanguage: sourceLangName,
          targetLanguage: getLanguageName(targetLanguage),
          date: new Date().toLocaleDateString(),
          createdBy: 'User',
          words: wordCount,
        };

        // Add the new translation to the beginning of the array
        setTranslations(prevTranslations => ({
          ...prevTranslations,
          translation_details: [translatedData, ...prevTranslations.translation_details]
        }));

        setOpenSnackbar(true); // Show the Snackbar
        setTimeout(() => {
          window.location.reload();
        }, 2000);// Reload after 2 seconds
      } catch (error) {
        console.error('Error translating file:', error);
        showErrorSnackbar('An error occurred during translation. Please try again.');
      }
    } else {
      showErrorSnackbar('Please select a target language.');
    }
  };
  const handleClearFile = () => {
    setSelectedFile(null);
    setFileUploaded(false);
    setSourceLanguage('');
    setTargetLanguage('');
    setShowFileUploadGrid(false);
  };



  const handlePreview = async (transid, translationUid) => {
    try {
      const requestData = { uid: translationUid, cid, transid };
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/translation/preview`, requestData);
      setPreviewContent(response.data.fileContents);
      setOpenPreviewDialog(true);
    }
    catch (error) {
      console.error('Error previewing file:', error);
      showErrorSnackbar('An error occurred during preview. Please try again.');
    }
  };

  const handleDownload = async (transid, translationUid) => {
    try {
      const requestData = { uid: translationUid, cid, transid };
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/translation/download`, requestData, {
        responseType: 'blob',
      });
      const blob = new Blob([response.data], { type: 'application/octet-stream' });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `translated_file_${Date.now()}.txt`); // Adjust filename as needed
      document.body.appendChild(link);
      link.click();
      link.remove(); // Clean up DOM after download
      setOpenDownloadSnackbar(true);
    } catch (error) {
      console.error('Error downloading file:', error);
      showErrorSnackbar('An error occurred during download. Please try again.');
    }
  };


  const handleDelete = async (transid, translationUid) => {
    if (!userPermissions) {
      console.error('User does not have permission to delete');
      return;
    }
    try {
      const requestData = { uid: translationUid, cid, transid };
      const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/translation/deletefile`, {
        data: requestData,
      });
      console.log('Delete API response:', response.data);
      setTranslations((prevTranslations) => ({
        ...prevTranslations,
        translation_details: prevTranslations.translation_details.filter(
          (translation) => translation.transid !== transid
        )
      }));
      setOpenDeleteDialog(false);
      setOpenDeleteSnackbar(true); // Show the delete snackbar
    } catch (error) {
      console.error('Error deleting translation:', error);
      showErrorSnackbar('An error occurred while deleting the translation. Please try again.');
    }
  };

  const renderTable = () => {
    const filteredTranslations = translations.translation_details?.filter(data =>
      data.file_name.toLowerCase().includes(searchQuery.toLowerCase())
    );



    return (
      <Box sx={{ mx: isSmallScreen ? 1 : 2 }}>
        {!filteredTranslations || filteredTranslations.length === 0 ? (
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight={300} sx={{ marginTop: '-100px' }}>
            <img src={illustration} alt="No translations available" style={{ maxWidth: '6%', height: 'auto' }} />
            <Typography variant="h6" color="textSecondary" align="center" fontSize="15px" sx={{ mt: 2 }}>
              {searchQuery ? "No matching translations found" : "Currently you have no translations"}
            </Typography>
          </Box>
        ) : (
          <TableContainer component={Paper}>
            <Table sx={{
              minWidth: 650,
              '& .MuiTableCell-root': {
                borderBottom: '1px solid rgba(0, 0, 0, 0.3)',
              },
            }} size="small" aria-label="members table">
              <TableHead sx={{ backgroundColor: "#A37CF0" }}>
                <TableRow sx={{ backgroundColor: '#A37CF0', height: '10px' }}>
                  <TableCell sx={{ color: 'white', fontSize: '13px', padding: '1px 8px', height: '10px', textAlign: 'center' }}>Filename</TableCell>
                  <TableCell sx={{ color: 'white', fontSize: '13px', padding: '1px 8px', height: '10px', textAlign: 'center' }}>Source Language</TableCell>
                  <TableCell sx={{ color: 'white', fontSize: '13px', padding: '1px 8px', height: '10px', textAlign: 'center' }}>Target Language</TableCell>
                  <TableCell sx={{ color: 'white', fontSize: '13px', padding: '1px 8px', height: '10px', textAlign: 'center' }}>Date</TableCell>
                  <TableCell sx={{ color: 'white', fontSize: '13px', padding: '1px 8px', height: '10px', textAlign: 'center' }}>Created by</TableCell>
                  <TableCell sx={{ color: 'white', fontSize: '13px', padding: '1px 8px', height: '10px', textAlign: 'center' }}>Words</TableCell>
                  <TableCell sx={{ color: 'white', fontSize: '13px', padding: '1px 8px', height: '10px', textAlign: 'center' }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredTranslations.map((data, index) => (
                  <StyledTableRow key={index}>


                    <TableCell sx={{ fontSize: '13px', textAlign: 'center' }}>{data.file_name}</TableCell>
                    <TableCell sx={{ fontSize: '13px', textAlign: 'center' }}>{getLanguageName(data.sourcelang)}</TableCell>
                    <TableCell sx={{ fontSize: '13px', textAlign: 'center' }}>{getLanguageName(data.targetlang)}</TableCell>
                    <TableCell sx={{ fontSize: '13px', textAlign: 'center' }}>{formatDate(data.created_at)}</TableCell>
                    <TableCell sx={{ fontSize: '13px', textAlign: 'center' }}>{data.email}</TableCell>
                    <TableCell sx={{ fontSize: '13px', textAlign: 'center' }}>{data.wordCount}</TableCell>
                    <TableCell sx={{ fontSize: '13px', textAlign: 'center' }}>

                      <Tooltip
                            title="Preview"
                            leaveDelay={0}
                            disableInteractive
                          >
                        <IconButton
                          sx={{ color: "#575757", padding: '6px',
                            '&:hover': {
                              color: '#8133F1', 
                            },
                           }}
                          onClick={() => handlePreview(data.transid, data.uid)}
                        >
                          <VisibilityIcon sx={{ fontSize: "21px" }} />
                        </IconButton>
                      </Tooltip>

                      <Tooltip
                            title="Download"
                            leaveDelay={0}
                            disableInteractive
                          >
                        <IconButton
                          aria-label="download"
                          sx={{ color: "#575757", padding: '6px',
                            '&:hover': {
                              color: '#8133F1', // Change color to blue on hover
                            },
                           }}
                          onClick={() => handleDownload(data.transid, data.uid)}
                        >
                          <DownloadIcon sx={{ fontSize: "21px" }} />
                        </IconButton>
                      </Tooltip>
                      {userPermissions && (
                          <Tooltip
                            title="Delete"
                            leaveDelay={0}
                            disableInteractive
                          >
                          <IconButton
                            aria-label="delete"
                            sx={{ color: "#575757", padding: '6px',
                              '&:hover': {
                                color: 'red', // Change color to blue on hover
                              },
                             }}
                            onClick={() => {
                              setDeleteTransId(data.transid);
                              setOpenDeleteDialog(true);
                            }}
                          >
                            <DeleteIcon sx={{ fontSize: "21px" }} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {userPermissions && (
          <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
            <DialogTitle sx={{ backgroundColor: "#E7EBF8" }}>Delete Translation</DialogTitle>
            <DialogContent sx={{ backgroundColor: "#E7EBF8" }}>
              <DialogContentText sx={{ backgroundColor: "#E7EBF8" }}>
                Are you sure you want to delete this translation?
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ backgroundColor: "#E7EBF8" }}>
              {/* <CustomButton variant="contained" sx={{
              textTransform: "none",
              minWidth: '48px',
              height: '21px',
              '&:hover': {
                backgroundColor: '#5900D9',
              },
              '& .MuiButton-label': {
                textAlign: 'center',
              },
              fontSize: "11px", backgroundColor: '#8133F1'
            }}
 
              color="primary"
              onClick={handleEditTerm}>
              Save
            </CustomButton> */}
              <Button onClick={() => setOpenDeleteDialog(false)} variant="contained" color="primary"
                sx={{
                  textTransform: "none",
                  minWidth: '48px',
                  height: '21px',
                  '&:hover': {
                    backgroundColor: '#5900D9',
                  },
                  '& .MuiButton-label': {
                    textAlign: 'center',
                  },
                  fontSize: "11px", backgroundColor: '#8133F1'
                }}
              >Cancel</Button>

              <Button onClick={() => {
                const translationToDelete = translations.translation_details.find(t => t.transid === deleteTransId);
                if (translationToDelete) {
                  handleDelete(deleteTransId, translationToDelete.uid);
                } else {
                  console.error('Translation not found');
                }
              }} variant="contained" color="primary"
                sx={{
                  textTransform: "none",
                  minWidth: '48px',
                  height: '21px',
                  '&:hover': {
                    backgroundColor: '#5900D9',
                  },
                  '& .MuiButton-label': {
                    textAlign: 'center',
                  },
                  fontSize: "11px", backgroundColor: '#8133F1'
                }} autoFocus>Delete</Button>
            </DialogActions>
          </Dialog>
        )}
        <Dialog open={openPreviewDialog} onClose={() => setOpenPreviewDialog(false)}>
          <DialogTitle sx={{ backgroundColor: "#E7EBF8" }}>File Preview</DialogTitle>
          <DialogContent sx={{ backgroundColor: "#E7EBF8" }}>
            <DialogContentText sx={{ backgroundColor: "#E7EBF8" }}>
              {previewContent}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ backgroundColor: "#E7EBF8" }}>
            <Button variant="contained" color="primary"
              sx={{
                textTransform: "none",
                minWidth: '48px',
                height: '21px',
                '&:hover': {
                  backgroundColor: '#5900D9',
                },
                '& .MuiButton-label': {
                  textAlign: 'center',
                },
                fontSize: "11px", backgroundColor: '#8133F1'
              }} autoFocus onClick={() => setOpenPreviewDialog(false)} >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <div>
      <Navbar />
      <SecondaryNavbar activeTab={activeTab} onTabChange={handleTabChange} sx={{ marginTop: '100px' }} />

      <Box sx={{ paddingTop: 6 }}>
        {activeTab === 0 ? (
          <>
            {!fileUploaded || !showFileUploadGrid ? (
              <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
                <Paper
                  {...getRootProps()}
                  elevation={3}
                  sx={{
                    backgroundColor: '#f0f8ff',
                    border: '2px dashed #8133F1',
                    borderRadius: '10px',
                    padding: '20px',
                    textAlign: 'center',
                    cursor: 'pointer',
                    width: '300px',
                    height: '150px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <input {...getInputProps()} />
                  <CloudUploadIcon sx={{ fontSize: 48, color: '#8133F1', mb: 2 }} />
                  <Typography variant="body1" color="textSecondary">
                    {isDragActive ? "Drop the file here" : "Drag and Drop here"}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    or
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: '#8133F1',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      '&:hover': {
                        color: '#5900D9'
                      }
                    }}
                  >
                    Upload file
                  </Typography>
                </Paper>
              </Box>
            ) : (

              <>
                <Paper variant="outlined" style={{ padding: '10px', marginBottom: '20px', marginLeft: "15px", marginRight: "15px" }}>
                  <Box>

                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="body2" color="textSecondary">File Name:</Typography>
                        <Typography variant="body1">{selectedFile.name}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <FormControl sx={{ minWidth: 120, height: '30px', width: '100%' }}>
                          <Select
                            value={sourceLanguage || "auto"}

                            onChange={handleSourceLanguageChange}
                            displayEmpty
                            sx={{
                              height: '30px',
                              width: '100%',
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'rgba(0, 0, 0, 0.38)', // grey 600
                              },
                              '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'rgba(0, 0, 0, 0.62)', // grey 800
                              },
                              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'rgba(0, 0, 0, 0.62)', // grey 800
                              },
                              '&:hover .MuiSvgIcon-root': {
                                color: '#5900D9', // Black on hover
                              },
                              '& .MuiSvgIcon-root': {
                                color: '#8133F1', // Initial color (grey)
                                transition: 'color 0.3s ease',
                              },
                            }}
                            MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
                          >
                            <MenuItem value="auto">Auto Detect</MenuItem>
                            {languagesList.map((language) => (
                              <MenuItem key={language.code} value={language.code}>
                                {language.name}
                              </MenuItem>
                            ))}
                          </Select>




                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <FormControl sx={{ minWidth: 120, height: '30px', width: '100%' }}>
                          <Select
                            value={targetLanguage}
                            onChange={handleTargetLanguageChange}
                            displayEmpty
                            sx={{
                              height: '30px',
                              width: '100%',
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'rgba(0, 0, 0, 0.38)', // grey 600
                              },
                              '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'rgba(0, 0, 0, 0.62)', // grey 800
                              },
                              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'rgba(0, 0, 0, 0.62)', // grey 800
                              },
                              '&:hover .MuiSvgIcon-root': {
                                color: '#5900D9', // Black on hover
                              },
                              '& .MuiSvgIcon-root': {
                                color: '#8133F1', // Initial color (grey)
                                transition: 'color 0.3s ease',
                              },


                            }}
                            MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
                          >
                            <MenuItem value="" disabled>
                              Please Select Target Language..
                            </MenuItem>
                            {languagesList.map((language) => (
                              <MenuItem key={language.code} value={language.code}>
                                {language.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <IconButton aria-label="clear-file" onClick={handleClearFile}>
                          <ClearIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mb: 2,
                    px: 2
                  }}
                >

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleTranslate}
                    sx={{
                      backgroundColor: '#8133F1',
                      textTransform: 'none',
                      fontSize: '14px',  // Minimum text size requirement
                      minWidth: '106px',
                      height: '36px',
                      padding: '0 16px',  // Minimum padding left-right
                      '&:hover': {
                        backgroundColor: '#5900D9',
                      },
                      '& .MuiButton-label': {
                        textAlign: 'center',
                      },
                    }}
                  >
                    Translate
                  </Button>
                </Box>
              </>
            )}
            {translations.length === 0 ? (
              <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight={300}>

              </Box>
            ) : (
              <Box sx={{ mb: 4, mt: 2, ml: 2 }}>

                <TextField
                  placeholder="Search by Filename"
                  value={searchQuery}
                  onChange={handleSearch}
                  variant="outlined"
                  sx={{
                    width: '280px',
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                      },
                      '&:hover fieldset': {
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                      },
                      '&.Mui-focused fieldset': {
                        borderWidth: '2px',
                        borderColor: '#424242',
                      },
                    },
                    '& .MuiInputBase-input::placeholder': {
                      color: 'rgba(0, 0, 0, 0.6)',
                      opacity: 1,
                      fontSize: '12px',
                      fontFamily: 'Poppins, sans-serif',
                    },
                  }}
                  InputProps={{
                    style: { height: '31px' },
                    startAdornment: (
                      <InputAdornment position="start" >
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            )}
            {renderTable()}
          </>
        ) : (
          <>
            {activeTab === 1 && (
              <Texttranslate />
            )}
          </>
        )}
      </Box>

      <Snackbar
        open={openSnackbar}
        // autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >

        <Alert onClose={() => setOpenSnackbar(false)} severity="success"
          sx={{
            backgroundColor: '#F8F8F8',
            color: '#878585',
            border: '1px solid #0DC42B',
            borderRadius: '4px',
            boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.3)',
          }}
          icon={<CheckCircleIcon sx={{ color: '#16D835', marginRight: '8px' }} />}
        >

          Hurrah! Translation done. Preview your file in table below
        </Alert>
      </Snackbar>








      <Snackbar
        open={openDownloadSnackbar}
        autoHideDuration={2000}
        onClose={() => setOpenDownloadSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setOpenDownloadSnackbar(false)} severity="success"
          sx={{
            backgroundColor: '#F8F8F8',
            color: '#878585',
            border: '1px solid #0DC42B',
            borderRadius: '4px',
            boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.3)',
          }}
          icon={<CheckCircleIcon sx={{ color: '#16D835', marginRight: '8px' }} />}
        >

          File Downloaded
        </Alert>
      </Snackbar>

      <Snackbar
        open={openDeleteSnackbar}
        // autoHideDuration={2000}
        onClose={() => setOpenDeleteSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setOpenDeleteSnackbar(false)} severity="success"
          sx={{
            backgroundColor: '#F8F8F8',
            color: '#878585',
            border: '1px solid #0DC42B',
            borderRadius: '4px',
            boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.3)',
          }}
          icon={<CheckCircleIcon sx={{ color: '#16D835', marginRight: '8px' }} />}
        >

          File Deleted

        </Alert>
      </Snackbar>

      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={4000}
        onClose={() => setOpenErrorSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setOpenErrorSnackbar(false)} severity="error"
          sx={{
            backgroundColor: '#F8F8F8',
            color: 'red',
            border: '1px solid red',
            borderRadius: '4px',
            boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.3)',
          }}
          icon={<ErrorIcon fontSize="inherit" style={{ color: 'red' }} />}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Filetranslate;
