import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Button,
  TextField,
  Typography,
  InputAdornment,
  Link,
  Grid,
  IconButton
} from '@mui/material';
import {
  AccountCircle,
  Email,
  Lock,
  Visibility,
  VisibilityOff,
  Cancel
} from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import translationGif from '../assets/translation.gif';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import companyimg from '../assets/company.png';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { color } from '@mui/system';

const theme = createTheme({
  palette: {
    primary: {
      main: '#30119E'
    },
    secondary: {
      main: '#9EB0EA'
    },
    grey: {
      600: '#757575',
      800: '#424242'
    }
  }
});

const Register = () => {
  const [selectedTab, setSelectedTab] = useState('organization');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const [formValues, setFormValues] = useState({
    organization: {
      name: '',
      email: '',
      password: '',
      organizationName: ''
    },
    personal: {
      name: '',
      email: '',
      password: ''
    }
  });
  const [formErrors, setFormErrors] = useState({
    organization: {},
    personal: {}
  });
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState({
    organization: false,
    personal: false
  });
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [showVerification, setShowVerification] = useState(false);
  const [passwordErrors, setPasswordErrors] = useState([]);

  useEffect(() => {
    setIsPopupVisible(true);
  }, []);

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    setFormErrors({
      organization: {},
      personal: {}
    });
  };

  const handleInputChange = (event, tab) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [tab]: {
        ...prevValues[tab],
        [name]: value
      }
    }));

    if (name === 'password') {
      const errors = [];
      if (value.length < 8) {
        errors.push('Must be at least 8 characters!');
      }
      if (!/\d/.test(value)) {
        errors.push('Must contain at least 1 number!');
      }
      if (!/[A-Z]/.test(value)) {
        errors.push('Must contain at least 1 Letter in Capital Case!');
      }
      if (!/[a-z]/.test(value)) {
        errors.push('Must contain at least 1 Letter in Small Case!');
      }
      if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
        errors.push('Must contain at least 1 Special Character!');
      }
      setPasswordErrors(errors);
    }

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [tab]: {
        ...prevErrors[tab],
        [name]: ''
      }
    }));
  };

  const togglePasswordVisibility = (tab) => {
    setShowPassword((prevShowPassword) => ({
      ...prevShowPassword,
      [tab]: !prevShowPassword[tab]
    }));
  };

  const validateForm = (tab) => {
    const errors = {};
    const values = formValues[tab];

    if (!values.name) {
      errors.name = 'Name is required';
    } else if (values.name.length > 20) {
      errors.name = 'Name cannot exceed 20 characters';
    }

    if (!values.email) {
      errors.email = 'Email is required';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email)) {
      errors.email = 'Invalid email format';
    }

    if (!values.password) {
      errors.password = 'Password is required';
    } else if (!/^(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(values.password)) {
      errors.password = 'Password must be at least 8 characters long and contain both numbers and symbols.';
    }

    if (tab === 'organization' && !values.organizationName) {
      errors.organizationName = 'Organization name is required';
    } else if (tab === 'organization' && values.organizationName.length < 3) {
      errors.organizationName = 'Organization name must be at least 3 characters long';
    }

    return errors;
  };

  const handleSubmit = async (event, tab) => {
    event.preventDefault();
    const errors = validateForm(tab);

    if (Object.keys(errors).length === 0) {
      try {
        let response;
        if (tab === 'organization') {
          const { name, email, password, organizationName } = formValues.organization;
          response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/register/organization`, {
            uname: name,
            password,
            email,
            cname: organizationName
          });
        } else {
          const { name, email, password } = formValues.personal;
          response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/register/personal`, {
            uname: name,
            password,
            email
          });
        }

        if (response.data.status) {
          setShowVerification(true);
          setTimeout(() => {
            navigate('/login');
          }, 3000);
        } else {
          console.error(response.data.message);
        }
      } catch (error) {
        if (error.response && error.response.status === 409) {
          setSnackbarMessage('User already exists');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        } else {
          console.error('Error during registration:', error);
        }
      }
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [tab]: errors
      }));
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '90vh',
          padding: { xs: 2, sm: 2, md: 2 },
          overflow: 'hidden',
          marginTop: '30px'
        }}
      >
        <Box
          sx={{
            width: '80%',
            maxWidth: '1000px',
            minHeight: { xs: '60%', sm: '480px', md: '530px', xl: '650px' },
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            boxShadow: 3,
            borderRadius: '2px',
          }}
          className={isPopupVisible ? 'popup' : ''}
        >
          <Box
            sx={{
              width: isSmallScreen ? '100%' : '50%',
              padding: 3,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#F9F4FB',
              overflowY: 'auto',
              maxHeight: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                height: '100%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  width: { xs: '100%', sm: '72%' },
                  mt: { xs: 2, sm: 2, xl: 6 },
                  mb: 3,
                  height: '30px',
                  '& > button': {
                    margin: 0,
                  },
                  '& > button:first-of-type': {
                    borderRadius: '2px 0 0 2px',
                    marginRight: 0,
                  },
                  '& > button:last-of-type': {
                    borderRadius: '0 2px 2px 0',
                    marginLeft: 0,
                  },
                  backgroundColor: '#F9F4FB',
                }}
              >
                <Button
                  fullWidth
                  onClick={() => handleTabChange('organization')}
                  sx={{
                    backgroundColor: selectedTab === 'organization' ? '#8133F1' : '#9EB0EA',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: selectedTab === 'organization' ? '#5900D9' : '#9EB0EA'
                    },
                    textTransform: 'none',
                  }}
                >
                  Organization
                </Button>
                <Button
                  fullWidth
                  onClick={() => handleTabChange('personal')}
                  sx={{
                    backgroundColor: selectedTab === 'personal' ? '#8133F1' : '#9EB0EA',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: selectedTab === 'personal' ? '#5900D9' : '#9EB0EA'
                    },
                    textTransform: 'none',
                  }}
                >
                  Personal
                </Button>
              </Box>
              <Box component="form" sx={{ width: { xs: '100%', sm: '72%' }, mt: 1 }} onSubmit={(e) => handleSubmit(e, selectedTab)}>
                <Grid container spacing={2.5}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Name"
                      name="name"
                      size="small"
                      value={formValues[selectedTab].name}
                      onChange={(e) => handleInputChange(e, selectedTab)}
                      error={!!formErrors[selectedTab].name}
                      helperText={formErrors[selectedTab].name}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircle sx={{
                              fontSize: 24,
                              color: '#707070',
                              '&:hover': {
                                color: '#4600A9'
                              }
                            }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'grey.600',
                          },
                          '&:hover fieldset': {
                            borderColor: 'grey.800',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'grey.800',
                          },
                          height: '43px',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Email"
                      name="email"
                      size="small"
                      value={formValues[selectedTab].email}
                      onChange={(e) => handleInputChange(e, selectedTab)}
                      error={!!formErrors[selectedTab].email}
                      helperText={formErrors[selectedTab].email}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Email sx={{
                              fontSize: 20,
                              color: '#707070',
                              '&:hover': {
                                color: '#4600A9'
                              }
                            }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'grey.600',
                          },
                          '&:hover fieldset': {
                            borderColor: 'grey.800',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'grey.800',
                          },
                          height: '43px',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Password"
                      name="password"
                      size="small"
                      type={showPassword[selectedTab] ? 'text' : 'password'}
                      value={formValues[selectedTab].password}
                      onChange={(e) => handleInputChange(e, selectedTab)}
                      error={passwordErrors.length > 0}
                      helperText={
                        passwordErrors.length > 0 && (
                          <Box sx={{ mt: 1 }}>
                            {passwordErrors.map((error, index) => (
                              <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
                                <Cancel color="error" fontSize="small" sx={{ mr: 1 }} />
                                <Typography variant="caption" color="error">
                                  {error}
                                </Typography>
                              </Box>
                            ))}
                          </Box>
                        )
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Lock sx={{
                              fontSize: 24,
                              color: '#707070',
                              '&:hover': {
                                color: '#4600A9',
                              }
                            }}
                            />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => togglePasswordVisibility(selectedTab)}
                              edge="end"
                              sx={{
                                fontSize: 24,
                                color: '#707070',
                                '&:hover': {
                                  color: '#4600A9'
                                }
                              }}
                            >
                              {showPassword[selectedTab] ? <Visibility sx={{ fontSize: 24 }} /> : <VisibilityOff sx={{ fontSize: 24 }} />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'grey.600',
                          },
                          '&:hover fieldset': {
                            borderColor: 'grey.800',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'grey.800',
                          },
                          height: '43px',
                        },
                        '& .MuiFormHelperText-root': {
                          marginLeft: 0,
                          marginRight: 0,
                        },
                      }}
                    />
                  </Grid>
                  {selectedTab === 'organization' && (
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Organization Name"
                        name="organizationName"
                        size="small"
                        value={formValues.organization.organizationName}
                        onChange={(e) => handleInputChange(e, 'organization')}
                        error={!!formErrors.organization.organizationName}
                        helperText={formErrors.organization.organizationName}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">

                              <AddBusinessIcon sx={{
                                fontSize: 24,
                                color: '#707070',
                                '&:hover': {
                                  color: '#4600A9'
                                }
                              }}
                              />
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: 'grey.600',
                            },
                            '&:hover fieldset': {
                              borderColor: 'grey.800',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: 'grey.800',
                            },
                            height: '43px',
                          },
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      sx={{
                        height: '43px',
                        mt: 1,
                        backgroundColor: '#8133F1',
                        '&:hover': {
                          backgroundColor: '#5900D9'
                        },
                        textTransform: 'none',
                        borderRadius: '5px', // Added border radius here
                      }}
                    >
                      Register
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={{ mt: 1.5, ml: 4 }}>
                      Already have an account?{' '}
                      <Link
                        component={RouterLink}
                        to="/login"
                        sx={{
                          color: '#8133F1',
                          '&:hover': {
                            color: '#5900D9', //Color on hover
                            textDecoration: 'underline'
                          }
                        }}
                      >
                        Login
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: isSmallScreen ? '100%' : '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              backgroundColor: '#30119E',
              overflow: 'hidden',
              borderRadius: '5px',
            }}
          >
            <img
              src={translationGif}
              alt="Translation"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                position: 'absolute',
                borderRadius: '5px',
              }}
            />
          </Box>
        </Box>
        {showVerification && (
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 9999,
              borderRadius: '5px',
            }}
          >
            <Box
              sx={{
                width: '90%',
                maxWidth: '400px',
                padding: 3,
                backgroundColor: 'white',
                borderRadius: '2px',
                textAlign: 'center'
              }}
            >
              <Typography variant="h6" sx={{ mb: 2 }}>
                Verify Your Email
              </Typography>
              <Typography variant="body1" sx={{ mb: 3 }}>
                A verification link has been sent to your email. Please check your email and click the link to verify your account.
              </Typography>
              <Button
                variant="contained"
                color="primary"

                sx={{
                  backgroundColor: '#30119E',
                  '&:hover': {
                    backgroundColor: '#30119E'
                  },
                  textTransform: 'none',
                }}
                onClick={() => setShowVerification(false)}
              >
                Close
              </Button>
            </Box>
          </Box>
        )}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          action={
            <Button color="inherit" onClick={handleSnackbarClose}>
              Close
            </Button>
          }
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Container>
    </ThemeProvider>
  );
};

export default Register;
