import React, { useState, useEffect } from 'react';
import { Button, Card, CardContent, Typography, Box, Container, Grid, List, ListItem, ListItemText, ListItemIcon, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoneIcon from '@mui/icons-material/Done';
import { createTheme, ThemeProvider, CssBaseline } from '@mui/material';
import { green } from '@mui/material/colors';
import RocketIcon from '../assets/rocket.png';
import DiscountIcon from '../assets/discount.png';
import CrownIcon from '../assets/crown.png';
import backimg from '../assets/backimg.jpg';

const GlobalStyle = () => (
  <style jsx global>{`
    body, html {
      margin: 0;
      padding: 0;
      overflow: hidden;
      height: 100%;
    }
  `}</style>
);

const Pricing = () => {
  const [open, setOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [selectedPlanIndex, setSelectedPlanIndex] = useState(-1);
  const navigate = useNavigate();

  useEffect(() => {
    const role = sessionStorage.getItem('role');
    if (role !== 'admin' && role !== 'personal-user') {
      navigate('/dashboard');
    }
  }, [navigate]);

  const theme = createTheme({
    palette: {
      primary: {
        main: '#673ab7',
      },
      secondary: {
        main: '#262D31',
      },
    },
    typography: {
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    },
  });

  const plans = [
    {
      title: 'Silver',
      price: '$200/Month',
      features: ['500 words per member', 'Add up to 15 team members', '200 Translations'],
      buttonLabel: 'Choose Your Plan',
      value: 125,
      icon: <img src={RocketIcon} alt="Rocket Icon" style={{ width: 24, height: 27}} />,
    },
    {
      title: 'Current Plan',
      price: 'Free',
      features: ['100 words', 'Add up to 4 team members', '50 Translations'],
      buttonLabel: 'Proceed',
      value: 250,
      icon: <img src={DiscountIcon} alt="Discount Icon" style={{ width: 24, height: 24 }} />,
    },
    {
      title: 'Platinum',
      price: '$50/Month',
      features: ['2000 words per member', 'Add up to 50 team members', '1000 Translations'],
      buttonLabel: 'Choose Your Plan',
      value: 500,
      icon: <img src={CrownIcon} alt="Crown Icon" style={{ width: 24, height: 30 }} />,
    },
  ];

  const colors = ['#EEEEEE', '#EEE1FF', '#FFFEDA'];

  const handleClickOpen = (plan) => {
    setSelectedPlan(plan.title);
    setOpen(true);
  };

  const handleClose = (proceed) => () => {
    setOpen(false);
    if (proceed) {
      navigate('/dashboard');
    }
  };

  const onClickHandler = (event, index, plan) => {
    event.preventDefault();
    setSelectedPlanIndex(index);
    setSelectedPlan(plan.title);
    if (plan.buttonLabel === 'Choose Your Plan') {
      setOpen(true);
    } else {
      navigate('/dashboard');
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div
        className="parent-div"
        style={{
          overflowY: 'auto',
          backgroundImage: `url(${backimg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '100vh',
        }}
      >
        <CssBaseline />
        <GlobalStyle />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            fontFamily: theme.typography.fontFamily,
          }}
        >
          <Container sx={{ flexGrow: 1, mt: 2 }}>
            <Box textAlign="center" sx={{ color: 'white', mb: { xs: 2, sm: 3, md: 4 } }}>
              <Typography variant="h3" gutterBottom>
                Flexible Plans & Pricing
              </Typography>
              <Typography variant="h5" gutterBottom>
                Explore our customizable translation plans designed to fit your unique requirements and budget!
              </Typography>
            </Box>
            <Grid container spacing={5} justifyContent="center" alignItems="center" sx={{ mt: { xs: 20, sm: 8, md: 0 } }}>
              {plans.map((plan, index) => (
                <Grid item xs={12} sm={6} md={4} key={index} sx={{ textAlign: 'center', ...(plan.title === 'Current Plan' && { mt: { xs: 0, sm: -15, md: -10 } }) }}>
                  <Card
                    variant="outlined"
                    sx={{
                      backgroundColor: colors[index],
                      color: '#400CB4',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                      '&:hover': {
                        transform: 'scale(1.05)',
                        boxShadow: '0 6px 12px rgba(0,0,0,0.2)',
                      },
                      borderRadius: '15px',
                      padding: '4px',
                      width: '100%',
                      ...(plan.title === 'Current Plan' && { marginTop: 'auto', marginBottom: 'auto' }),
                    }}
                  >
                    <CardContent>
                      {plan.icon}
                      <Typography
                        variant="h6"
                        component="div"
                        textAlign="center"
                        gutterBottom
                        sx={{ fontWeight: 'bold', color: 'black' }}
                      >

                        {plan.title}

                      </Typography>
                      <Typography
                        variant="h6"
                        component="div"
                        textAlign="center"
                        gutterBottom
                        sx={{ fontWeight: 'bold', color: '#4600A9' }}
                      >
                        <div>{plan.price}</div>
                      </Typography>

                      <Typography
                        variant="body1"
                        component="div"
                        textAlign="center"
                        gutterBottom
                        sx={{ color: '#989898' }}

                      >
                        Save ${plan.value} with annual subscription

                      </Typography>



                      <List sx={{ color: 'black' }}>
                        {plan.features.map((feature, idx) => (
                          <ListItem key={idx}>
                            <ListItemIcon>
                              <CheckCircleIcon sx={{ color: '#16D835' }} />
                            </ListItemIcon>
                            <ListItemText primary={feature} />
                          </ListItem>
                        ))}
                      </List>
                    </CardContent>
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                      {plan.title === 'Current Plan' ? (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={(e) => onClickHandler(e, index, plan)}
                          sx={{
                            backgroundColor: '#8133F1',
                            textTransform: 'none',
                            fontSize: '14px',
                            minWidth: '106px',
                            height: '36px',
                            padding: '0 16px',
                            '&:hover': {
                              backgroundColor: '#5900D9',
                            },
                            '& .MuiButton-label': {
                              textAlign: 'center',
                            },
                          }}
                        >
                          {plan.buttonLabel}
                        </Button>
                      ) : (
                        <Button
                          variant="outlined"
                          onClick={(e) => onClickHandler(e, index, plan)}
                          sx={{
                            backgroundColor: '#FFFFFF',
                            color: '#7e3ff2',
                            borderColor: '#7e3ff2',
                            borderWidth: '2px',
                            borderRadius: '5px',
                            textTransform: 'none',
                            fontSize: '14px',
                            minWidth: '106px',
                            height: '36px',
                            padding: '0 16px',
                            '&:hover': {
                              backgroundColor: '#7e3ff2',
                              color: 'white',
                              borderColor: '#7e3ff2',
                            },
                            '& .MuiButton-label': {
                              textAlign: 'center',
                            },
                          }}
                        >
                          {plan.buttonLabel}
                        </Button>
                      )}
                    </Box>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
        <Dialog
          open={open}
          onClose={handleClose(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            sx: {
              backgroundColor: '#f5f5f5',
              borderRadius: '15px',
              padding: '20px',
              border: '2px  #30119E',
            },
          }}
        >
          <DialogTitle id="alert-dialog-title" sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <DoneIcon sx={{ color: green[500], fontSize: '2rem' }} />
            {`Thank You for showing interest in ${selectedPlan}`}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ color: '#333', textAlign: 'center' }}>
              Our Team will get back to you soon!!
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button
              onClick={handleClose(true)}
              sx={{
                backgroundColor: '#30119E',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#A37CF0',
                },
                fontWeight: 'bold',
                textTransform: 'none',
                borderRadius: '5px',
                padding: '10px 20px',
              }}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </ThemeProvider>
  );
};

export default Pricing;
