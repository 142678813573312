
import React, { useState, useEffect } from 'react';
import {
    Box, Typography, Grid, Button, IconButton, InputAdornment, TextField, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, useMediaQuery, Dialog, DialogTitle,
    DialogContent, DialogContentText, DialogActions,
    Tooltip
} from '@mui/material';
import { Translate, Refresh, Search } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import styled from '@emotion/styled';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import illustration from '../assets/paper.png';
import { Snackbar, Alert } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
const StyledTableRow = styled(TableRow)`
  height: 30px;
  & > td {
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;


const Texttranslate = () => {


    const [searchTerm, setSearchTerm] = useState('');
    const uid = sessionStorage.getItem('uid');
    const cid = sessionStorage.getItem('cid');
    const [userPermissions, setUserPermissions] = useState(false);

    const [filteredTranslations, setFilteredTranslations] = useState([]);
    const [formData, setFormData] = useState({
        sourceLanguage: 'auto',
        targetLanguage: '',
        textToTranslate: '',
        translatedText: ''
    });

    const [languagesList, setLanguagesList] = useState([]);
    const [translations, setTranslations] = useState([]); // State to hold fetched translations
    const [tableData, setTableData] = useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteTransId, setDeleteTransId] = useState(null);
    const [previewContent, setPreviewContent] = useState('');
    const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const [isLoadingLanguages, setIsLoadingLanguages] = useState(true);
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'error',
        isDownload: false
    });
    const showErrorSnackbar = (message) => {
        setErrorMessage(message);
        setOpenErrorSnackbar(true);
    };
    useEffect(() => {
        fetchTranslations();
        getLanguagesList();
        const permissions = sessionStorage.getItem('permissions');
        setUserPermissions(permissions === 'true');
    }, [uid, cid]);

    useEffect(() => {
        filterTranslations(searchTerm);
    }, [translations]);

    const fetchTranslations = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/translation/history`, { uid, cid });

            const sortedTranslations = response.data.translation_details.sort((a, b) =>
                new Date(b.created_at) - new Date(a.created_at)
            );
            setTranslations({ translation_details: sortedTranslations });
        } catch (error) {
            console.error('Error fetching translations:', error);
        }
    };


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    };
    const filterTranslations = (searchTerm) => {
        if (!searchTerm) {
            setFilteredTranslations(translations.translation_details || []);
        } else {
            const filtered = translations.translation_details?.filter(data =>
                data.file_name.toLowerCase().includes(searchTerm.toLowerCase())
            ) || [];
            setFilteredTranslations(filtered);
        }
    };
    const handleInputChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };
    useEffect(() => {
        fetchTranslations();
        loadLanguages();
        const permissions = sessionStorage.getItem('permissions');
        setUserPermissions(permissions === 'true');
    }, [uid, cid]);

    const loadLanguages = async () => {
        setIsLoadingLanguages(true);
        const cachedLanguages = localStorage.getItem('languagesList');
        if (cachedLanguages) {
            setLanguagesList(JSON.parse(cachedLanguages));
            setIsLoadingLanguages(false);
        } else {
            await getLanguagesList();
        }
    };
    const getLanguagesList = async (retryCount = 3) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/translation/sourcelanguages`);
            const languages = response.data.source_languages;

            if (!languages) {
                throw new Error('No languages data found');
            }

            const languagesArray = Object.keys(languages).map(code => ({
                code,
                name: languages[code].name,
            }));

            setLanguagesList(languagesArray);
            localStorage.setItem('languagesList', JSON.stringify(languagesArray));
            setIsLoadingLanguages(false);
        } catch (error) {
            console.error('Error fetching languages:', error);
            if (retryCount > 0) {
                console.log(`Retrying... (${retryCount} attempts left)`);
                setTimeout(() => getLanguagesList(retryCount - 1), 1000);
            } else {
                setIsLoadingLanguages(false);
                // Show an error message to the user
                setSnackbar({
                    open: true,
                    message: 'Failed to load languages. Please try refreshing the page.',
                    severity: 'error'
                });
            }
        }
    };
    const getLanguageName = (code) => {
        if (code === 'auto') return 'Auto Detect';
        const language = languagesList.find(lang => lang.code === code);
        return language ? language.name : code;
    };

    const handleTranslate = async () => {
        if (!formData.textToTranslate.trim()) {
            setSnackbar({
                open: true,
                message: 'Please enter text to be translated',
                severity: 'error'
            });
            return;
        }
        try {
            const requestData = {
                uid: uid,
                cid: cid,
                text: formData.textToTranslate,
                targetLang: formData.targetLanguage
            };

            if (formData.sourceLanguage !== 'auto') {
                requestData.sourceLang = formData.sourceLanguage;
            }

            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/translation/translateText`, requestData);

            const translatedText = response.data.translatedText;
            const detectedSourceLang = response.data.sourceLang || formData.sourceLanguage;

            setFormData((prevFormData) => ({
                ...prevFormData,
                translatedText: translatedText
            }));

            // const translatedData = {
            //     filename: 'Translated Text',
            //     status: 'Completed',
            //     sourceLanguage: detectedSourceLang,
            //     targetLanguage: formData.targetLanguage,
            //     date: new Date().toLocaleDateString(),
            //     createdBy: 'User',
            //     words: formData.textToTranslate.split(/\s+/).length,
            // };
            const translatedData = {
                transid: Date.now(), // Generate a temporary unique ID
                filename: 'Translated Text',
                status: 'Completed',
                sourceLanguage: detectedSourceLang,
                targetLanguage: formData.targetLanguage,
                date: new Date().toLocaleDateString(),
                createdBy: 'User',
                words: formData.textToTranslate.split(/\s+/).length,
            };

            setTranslations(prevTranslations => ({
                ...prevTranslations,
                translation_details: [translatedData, ...(prevTranslations.translation_details || [])]
            }));

            setTableData((prevData) => [...prevData, translatedData]);

            if (formData.sourceLanguage === 'auto') {
                setSnackbar({
                    open: true,
                    message: `Hurrah!! Translation done, Preview Your  File in table below`,
                    severity: 'success'
                });
            }
            setTimeout(() => {
                window.location.reload();
            }, 8000);
        } catch (error) {
            console.error('Error translating text:', error);
            setSnackbar({
                open: true,
                message: 'Please select Target Language',
                severity: 'error'
            });
        }
    };
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };

    const handleRefresh = () => {
        setFormData({
            sourceLanguage: '',
            targetLanguage: '',
            textToTranslate: '',
            translatedText: ''
        });
        setTableData([]);
    };

    const handleSearchChange = (event) => {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);
        filterTranslations(searchTerm);
    };

    const filteredData = tableData.filter(data =>
        data.filename.toLowerCase().includes(searchTerm.toLowerCase())
    );


    const handlePreview = async (transid, translationUid) => {
        try {
            const requestData = { uid: translationUid, cid, transid };
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/translation/preview`, requestData);
            setPreviewContent(response.data.fileContents);
            setOpenPreviewDialog(true);
        } catch (error) {
            console.error('Error previewing file:', error);
            showErrorSnackbar('An error occurred during preview. Please try again.');
        }
    };



    const handleDownload = async (transid, translationUid) => {
        try {
            const requestData = { uid: translationUid, cid, transid };
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/translation/download`, requestData, {
                responseType: 'blob',
            });
            const blob = new Blob([response.data], { type: 'application/octet-stream' });
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `translated_file_${Date.now()}.txt`); // Adjust filename as needed
            document.body.appendChild(link);
            link.click();
            link.remove(); // Clean up DOM after download
            setSnackbar({
                open: true,
                message: 'File Downloaded Successfully',
                severity: 'success',
                isDownload: true
            });
        } catch (error) {
            console.error('Error downloading file:', error);
            setSnackbar({
                open: true,
                message: 'Error downloading file. Please try again.',
                severity: 'error',
                isDownload: true
            });
        }
    };


    const handleDelete = async (transid, translationUid) => {
        if (!userPermissions) {
            console.error('User does not have permission to delete');
            return;
        }
        try {
            const requestData = { uid: translationUid, cid, transid };
            const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/translation/deletefile`, {
                data: requestData,
            });
            console.log('Delete API response:', response.data);
            setTranslations((prevTranslations) => ({
                ...prevTranslations,
                translation_details: prevTranslations.translation_details.filter(
                    (translation) => translation.transid !== transid
                )
            }));
            setOpenDeleteDialog(false);
            setSnackbar({
                open: true,
                message: 'File Deleted Successfully',
                severity: 'success',
                isDownload: false
            });
        } catch (error) {
            console.error('Error deleting translation:', error);
            setSnackbar({
                open: true,
                message: 'Error deleting file. Please try again.',
                severity: 'error',
                isDownload: false
            });
        }
    };



    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box
                sx={{
                    backgroundColor: '#E2E7FC',
                    background: 'linear-gradient(to top right, #f0f0f0, #e7ebed)',
                    borderRadius: '5px',
                    p: 1,
                    mb: 2,
                    border: '2px solid #d0d0d0',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginLeft: '3.8%',
                    marginRight: '4.5%',
                    marginTop: '0%'
                }}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Box sx={{ border: '2px', borderRadius: '5px', p: 2, width: '90%', marginLeft: '4%' }}>
                            <Typography variant="h6" sx={{ textAlign: 'left', mt: 1 }}>
                                Source Language
                            </Typography>
                            <TextField
                                //label="Source Language"
                                select
                                variant="standard"
                                disabled={isLoadingLanguages}
                                sx={{
                                    mb: 2,
                                    width: '100%',
                                    '& .MuiInput-underline:before': {
                                        borderBottomColor: 'rgba(0, 0, 0, 0.38)', // Default underline color
                                    },
                                    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                        borderBottomColor: 'rgba(0, 0, 0, 0.62)', // Hover underline color
                                    },
                                    '& .MuiInput-underline:after': {
                                        borderBottomColor: '#575757', // Focused underline color
                                    },
                                    '&:hover .MuiSvgIcon-root': {
                                        color: '#5900D9',
                                    },
                                    '& .MuiSvgIcon-root': {
                                        color: '#8133F1',
                                        transition: 'color 0.3s ease',
                                    },
                                }}
                                name="sourceLanguage"
                                value={formData.sourceLanguage}
                                onChange={handleInputChange}
                            >
                                {isLoadingLanguages ? (
                                    <MenuItem disabled>Loading languages...</MenuItem>
                                ) : (
                                    languagesList.map((option) => (
                                        <MenuItem key={option.code} value={option.code}>
                                            {option.name}
                                        </MenuItem>
                                    ))
                                )}
                            </TextField>

                            <TextField
                                label="Type or paste some text to translate"
                                multiline
                                rows={4}
                                fullWidth
                                variant="filled"
                                name="textToTranslate"
                                value={formData.textToTranslate}
                                onChange={handleInputChange}
                                sx={{
                                    '& .MuiFilledInput-underline:after': {
                                        borderBottomColor: '#575757',
                                    },
                                    '& .MuiInputLabel-root.Mui-focused': {
                                        color: '#575757',
                                    },
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{ border: '2px', borderRadius: '5px', p: 2, width: '90%', marginLeft: '2%' }}>
                            <Typography variant="h6" sx={{ textAlign: 'left', mt: 1 }}>
                                Target Language
                            </Typography>
                            <TextField
                                select
                                variant="standard"
                                placeholder="Please Select Target Language"
                                sx={{
                                    mb: 2,
                                    width: '100%',
                                    '& .MuiInput-underline:before': {
                                        borderBottomColor: 'rgba(0, 0, 0, 0.38)', // Default underline color
                                    },
                                    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                        borderBottomColor: 'rgba(0, 0, 0, 0.62)', // Hover underline color
                                    },
                                    '& .MuiInput-underline:after': {
                                        borderBottomColor: '#575757', // Focused underline color
                                    },
                                    '&:hover .MuiSvgIcon-root': {
                                        color: '#5900D9',
                                    },
                                    '& .MuiSvgIcon-root': {
                                        color: '#8133F1',
                                        transition: 'color 0.3s ease',
                                    },
                                    '& .MuiSelect-select': {
                                        '&:focus': {
                                            backgroundColor: 'transparent',
                                        },
                                    },
                                }}
                                name="targetLanguage"
                                value={formData.targetLanguage}
                                onChange={handleInputChange}
                                SelectProps={{
                                    displayEmpty: true,
                                    renderValue: (selected) => {
                                        if (selected.length === 0) {
                                            return <Typography style={{ color: '#575757' }}>Please Select Target Language</Typography>
                                        }
                                        return languagesList.find(lang => lang.code === selected)?.name || '';
                                    },
                                }}
                            >
                                {languagesList.map((option) => (
                                    <MenuItem key={option.code} value={option.code}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                label="Translated Text"
                                multiline
                                rows={4}
                                fullWidth
                                variant="filled"
                                name="translatedText"
                                value={formData.translatedText}
                                onChange={handleInputChange}
                                InputProps={{
                                    readOnly: true,
                                }}
                                sx={{
                                    '& .MuiFilledInput-underline:after': {
                                        borderBottomColor: '#575757',
                                    },
                                    '& .MuiInputLabel-root.Mui-focused': {
                                        color: '#575757',
                                    },
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>


                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleTranslate}
                                sx={{
                                    backgroundColor: '#8133F1',
                                    textTransform: 'none',
                                    fontSize: '14px',  // Minimum text size requirement
                                    minWidth: '106px',
                                    height: '36px',
                                    padding: '0 16px',
                                    mr: 2,
                                    '&:hover': {
                                        backgroundColor: '#5900D9',
                                    },
                                    '& .MuiButton-label': {
                                        textAlign: 'center',
                                    },
                                }}
                            >
                                Translate
                            </Button>




                            <Button
                                variant="contained"
                                onClick={handleRefresh}
                                sx={{
                                    textTransform: 'none',
                                    fontSize: '14px',  // Minimum text size requirement
                                    minWidth: '106px',
                                    height: '36px',
                                    padding: '0 16px',
                                    backgroundColor: "#9E9E9E",

                                    '&:hover': {
                                        backgroundColor: '#707070',
                                    },
                                }}
                            >
                                Refresh
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>


            {translations.length === 0 ? (
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight={300}>

                </Box>
            ) : (

                <Box sx={{ mb: 4, mt: 2, ml: 2 }}>



                    <TextField
                        placeholder="Search by Filename"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        variant="outlined"
                        sx={{
                            width: '280px',
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'rgba(0, 0, 0, 0.23)',
                                },
                                '&:hover fieldset': {
                                    borderColor: 'rgba(0, 0, 0, 0.23)',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#424242',
                                },
                            },
                            '& .MuiInputBase-input::placeholder': {
                                color: 'rgba(0, 0, 0, 0.6)',
                                opacity: 1,
                                fontSize: '12px',
                                fontFamily: 'Poppins, sans-serif',
                            },
                        }}
                        InputProps={{
                            style: { height: '31px' },
                            startAdornment: (
                                <InputAdornment position="start" >
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            )}


            <Box sx={{ ml: '1%', mr: '1%' }}>
                {filteredTranslations.length === 0 ? (
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ marginTop: '40px' }}  >
                        <img src={illustration} alt="No translations available" style={{ maxWidth: '6%', height: 'auto' }} />
                        <Typography variant="h6" color="textSecondary" align="center" fontSize="15px" sx={{ mt: 2 }}>
                            {searchTerm ? "No matching translations found" : "Currently you have no translations"}
                        </Typography>
                    </Box>
                ) : (
                    <TableContainer component={Paper}>
                        <Table sx={{
                            minWidth: 650,
                            '& .MuiTableCell-root': {
                                borderBottom: '1px solid rgba(0, 0, 0, 0.3)',
                            },
                        }}
                            size="small" aria-label="members table">
                            <TableHead sx={{ backgroundColor: "#A37CF0" }}>
                                <TableRow sx={{ backgroundColor: '#A37CF0', height: '10px' }}>
                                    <TableCell sx={{ color: 'white', fontSize: '13px', padding: '1px 8px', height: '10px', textAlign: 'center' }}>Filename</TableCell>
                                    <TableCell sx={{ color: 'white', fontSize: '13px', padding: '1px 8px', height: '10px', textAlign: 'center' }}>Source Language</TableCell>
                                    <TableCell sx={{ color: 'white', fontSize: '13px', padding: '1px 8px', height: '10px', textAlign: 'center' }}>Target Language</TableCell>
                                    <TableCell sx={{ color: 'white', fontSize: '13px', padding: '1px 8px', height: '10px', textAlign: 'center' }}>Date</TableCell>
                                    <TableCell sx={{ color: 'white', fontSize: '13px', padding: '1px 8px', height: '10px', textAlign: 'center' }}>Created by</TableCell>
                                    <TableCell sx={{ color: 'white', fontSize: '13px', padding: '1px 8px', height: '10px', textAlign: 'center' }}>Words</TableCell>
                                    <TableCell sx={{ color: 'white', fontSize: '13px', padding: '1px 8px', height: '10px', textAlign: 'center' }}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredTranslations.map((data, index) => (
                                    <StyledTableRow key={index}>


                                        <TableCell sx={{ fontSize: '13px', textAlign: 'center' }}>{data.file_name}</TableCell>
                                        <TableCell sx={{ fontSize: '13px', textAlign: 'center' }}>{getLanguageName(data.sourcelang)}</TableCell>
                                        <TableCell sx={{ fontSize: '13px', textAlign: 'center' }}>{getLanguageName(data.targetlang)}</TableCell>
                                        <TableCell sx={{ fontSize: '13px', textAlign: 'center' }}>{formatDate(data.created_at)}</TableCell>
                                        <TableCell sx={{ fontSize: '13px', textAlign: 'center' }}>{data.email}</TableCell>
                                        <TableCell sx={{ fontSize: '13px', textAlign: 'center' }}>{data.wordCount}</TableCell>
                                        <TableCell sx={{ fontSize: '13px', textAlign: 'center' }}>

                                            <Tooltip
                                                title="Preview"
                                                leaveDelay={0}
                                                disableInteractive
                                            >

                                                <IconButton
                                                    sx={{
                                                        color: "#575757", padding: "6px",
                                                        '&:hover': {
                                                            color: '#8133F1', // Change color to blue on hover
                                                        },
                                                    }}
                                                    onClick={() => handlePreview(data.transid, data.uid)}
                                                >
                                                    <VisibilityIcon sx={{ fontSize: "21px" }} />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip
                                                title="Download"
                                                leaveDelay={0}
                                                disableInteractive
                                            >
                                                <IconButton
                                                    aria-label="download"
                                                    sx={{
                                                        color: "#575757", padding: "6px",
                                                        '&:hover': {
                                                            color: '#8133F1', // Change color to blue on hover
                                                        },
                                                    }}
                                                    onClick={() => handleDownload(data.transid, data.uid)}
                                                >
                                                    <DownloadIcon sx={{ fontSize: "21px" }} />
                                                </IconButton>
                                            </Tooltip>
                                            {userPermissions && (

                                                <Tooltip
                                                    title="Delete"
                                                    leaveDelay={0}
                                                    disableInteractive
                                                >
                                                    <IconButton
                                                        aria-label="delete"
                                                        sx={{
                                                            color: "#575757", padding: "6px",
                                                            '&:hover': {
                                                                color: 'red', // Change color to blue on hover
                                                            },
                                                        }}
                                                        onClick={() => {
                                                            setDeleteTransId(data.transid);
                                                            setOpenDeleteDialog(true);
                                                        }}
                                                    >
                                                        <DeleteIcon sx={{ fontSize: "21px" }} />
                                                    </IconButton>

                                                </Tooltip>
                                            )}
                                        </TableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                {userPermissions && (
                    <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
                        <DialogTitle sx={{ backgroundColor: "#E7EBF8" }}>Delete Translation</DialogTitle>
                        <DialogContent sx={{ backgroundColor: "#E7EBF8" }}>
                            <DialogContentText sx={{ backgroundColor: "#E7EBF8" }}>
                                Are you sure you want to delete this translation?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ backgroundColor: "#E7EBF8" }}>
                            <Button onClick={() => setOpenDeleteDialog(false)} variant="contained" color="primary"
                                sx={{
                                    textTransform: "none",
                                    minWidth: '48px',
                                    height: '21px',
                                    '&:hover': {
                                        backgroundColor: '#5900D9',
                                    },
                                    '& .MuiButton-label': {
                                        textAlign: 'center',
                                    },
                                    fontSize: "11px", backgroundColor: '#8133F1'
                                }}>Cancel</Button>
                            <Button onClick={() => {
                                const translationToDelete = translations.translation_details.find(t => t.transid === deleteTransId);
                                if (translationToDelete) {
                                    handleDelete(deleteTransId, translationToDelete.uid);
                                } else {
                                    console.error('Translation not found');
                                }
                            }} variant="contained" color="primary"
                                sx={{
                                    textTransform: "none",
                                    minWidth: '48px',
                                    height: '21px',
                                    '&:hover': {
                                        backgroundColor: '#5900D9',
                                    },
                                    '& .MuiButton-label': {
                                        textAlign: 'center',
                                    },
                                    fontSize: "11px", backgroundColor: '#8133F1'
                                }} autoFocus>Delete</Button>
                        </DialogActions>
                    </Dialog>
                )}

                <Dialog
                    open={openPreviewDialog}
                    onClose={() => setOpenPreviewDialog(false)}
                >
                    <DialogTitle sx={{ backgroundColor: "#E7EBF8", paddingRight: '48px' }}>
                        File Preview
                        <IconButton
                            aria-label="close"
                            onClick={() => setOpenPreviewDialog(false)}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                // color: (theme) => theme.palette.grey[500],
                                color: "black"
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent sx={{ backgroundColor: "#E7EBF8" }}>
                        <DialogContentText sx={{ backgroundColor: "#E7EBF8" }}>
                            {previewContent}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ backgroundColor: "#E7EBF8" }}>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{
                                textTransform: "none",
                                minWidth: '48px',
                                height: '21px',
                                '&:hover': {
                                    backgroundColor: '#5900D9',
                                },
                                '& .MuiButton-label': {
                                    textAlign: 'center',
                                },
                                fontSize: "11px",
                                backgroundColor: '#8133F1'
                            }}
                            autoFocus
                            onClick={() => setOpenPreviewDialog(false)}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbar.severity}
                    sx={{
                        backgroundColor: '#F8F8F8',
                        color: '#878585',
                        border: snackbar.severity === 'success' ? '1px solid #0DC42B' : '1px solid red',
                        borderRadius: '4px',
                        boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.3)',
                    }}
                    iconMapping={{
                        success: <CheckCircleIcon fontSize="inherit" style={{ color: '#16D835' }} />,
                        error: <ErrorIcon fontSize="inherit" style={{ color: 'red' }} />,

                    }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>


            {/* <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbar.severity}
                    sx={{
                        backgroundColor: '#F8F8F8',
                        color: '#878585',
                        border: '1px solid #0DC42B',
                        borderRadius: '4px',
                        boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.3)',
                      }}
                      icon={<CheckCircleIcon sx={{ color: '#16D835', marginRight: '8px' }} />}
                    >

                
                    {snackbar.message}
                </Alert>
            </Snackbar>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} 
                sx={{
                    backgroundColor: '#F8F8F8',
                    color: '#878585',
                    border: '1px solid #0DC42B',
                    borderRadius: '4px',
                    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.3)',
                  }}
                  icon={<CheckCircleIcon sx={{ color: '#16D835', marginRight: '8px' }} />}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar> */}
        </Box >

    );
};

export default Texttranslate;