import React from 'react';
import { AppBar, Tabs, Tab, Box } from '@mui/material';

const SecondaryNavbar = ({ activeTab, onTabChange, sx }) => {
  return (
    <Box sx={{ flexGrow: 0, marginTop:'3%'}}> {/* Apply additional styles */}
      <AppBar position="static" color="transparent" elevation={0}>
        <Tabs
          value={activeTab}
          onChange={onTabChange}
          textColor="primary"
          indicatorColor="primary"
          sx={{
            '& .MuiTabs-indicator': { backgroundColor: '#8133F1',height: 2 },
            '& .MuiTab-root.Mui-selected': { fontWeight: 'semibold', color: '#8133F1',height: 2 }
          }}
        >
          <Tab sx={{ textTransform: "none", fontSize: "15px" }} label="File Translations" />
          <Tab sx={{ textTransform: "none", fontSize: "15px" }} label="Text Translations" />
        </Tabs>
      </AppBar>
    </Box>
  );
};

export default SecondaryNavbar;
