import React, { useEffect, useState } from 'react';
import { Container, Grid, Paper, Typography, useMediaQuery, Box, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Line } from 'react-chartjs-2';
import ReactSpeedometer from 'react-d3-speedometer';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import Navbar from './Navbar';
import totalcreditsImage from '../assets/translation_credits.svg';
import availcreditsImage from '../assets/credits.svg';
import dictTermsImage from '../assets/dictionary.svg';
import groupImage from '../assets/group.svg';
import axios from 'axios';
// import GuideMe from './GuideMe'; 


ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);



const CircularProgressBar = ({ initialDaysLeft, totalDays }) => {
    const [daysLeft, setDaysLeft] = useState(initialDaysLeft);
    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => {
            setViewportWidth(window.innerWidth);
            setViewportHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        const timer = setInterval(() => {
            setDaysLeft(prevDays => Math.max(prevDays - 1, 0));
        }, 86400000); // Update every 24 hours

        return () => {
            window.removeEventListener('resize', handleResize);
            clearInterval(timer);
        };
    }, []);






    // Calculate radius based on viewport dimensions
    const radius = Math.min(viewportWidth, viewportHeight) * 0.1; // Adjust multiplier as needed
    const stroke = 10;
    const normalizedRadius = radius - stroke * 2;
    const circumference = normalizedRadius * 2 * Math.PI;
    const offset = circumference - daysLeft * (circumference / totalDays);
    const fontSize = Math.round(radius / 5); // Adjust the divisor as needed for desired scaling

    return (
        <svg height={radius * 2} width={radius * 2}>
            <circle
                stroke="#e0e0e0"
                fill="transparent"
                strokeWidth={stroke}
                r={normalizedRadius}
                cx={radius}
                cy={radius}
            />
            <circle
                stroke="#8133F1"
                fill="transparent"
                strokeWidth={stroke}
                strokeDasharray={`${circumference} ${circumference}`}
                strokeDashoffset={offset}
                r={normalizedRadius}
                cx={radius}
                cy={radius}
                style={{ transition: 'stroke-dashoffset 0.35s', transform: 'rotate(-90deg)', transformOrigin: '50% 50%' }}
            />
            <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontSize={fontSize} fontFamily="Arial">
                {`${daysLeft} ${daysLeft === 1 ? 'Day' : 'Days'} Left`}
            </text>
        </svg>

    );

   


};

const MultiColorSemiCircularGauge = ({ value }) => {
    const maxValue = Math.max(10000, value * 2); // Adjust the max value dynamically
    return (
        <div style={{
            display: 'flex', alignItems: 'center', justifyContent: 'center', height: '26vh',
            maxWidth: '400px', margin: '0', paddingTop: "1%"
        }}>
            <ReactSpeedometer
                minValue={0}
                maxValue={maxValue}
                value={value}
                needleHeightRatio={0.8}
                startColor={'#8133F1'}
                endColor={'#CEB0FA'}
                segments={3}
                segmentColors={['#8133F1', '#CEB0FA']}
                currentValueText={`${value}`}
                ringWidth={25}
                needleTransitionDuration={3000}
                needleTransition="easeQuadInOut"
                customSegmentStops={[0, maxValue / 2, maxValue]}
                ringSegments={3}
                gradient
                textColor="#333"
                needleColor={'#333'}
                needleBaseColor={'#333'}
                width={250}
                height={155}
            />
        </div>
    );
};
const getUidFromSessionStorage = () => {
    return sessionStorage.getItem('uid') || '';
};


const Dashboard = () => {
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
    const [totalCredits, setTotalCredits] = useState(10000);
    const [availableCredits, setAvailableCredits] = useState([]);
    const [teamMembers, setTeamMembers] = useState([]);
    const [dictionaryTerms, setDictionaryTerms] = useState(0);
    // const [cid, setCid] = useState(null);
    const [translationsCount, setTranslationsCount] = useState(0);
    // const [uid, setUid] = useState(getUidFromSessionStorage());
    const [translations, setTranslations] = useState([]);
    const [chartData, setChartData] = useState(null);
    const [cid, setCid] = useState(null);
    const [uid, setUid] = useState(null);
    const [guideOpen, setGuideOpen] = useState(false);

    const handleGuideOpen = () => {
        setGuideOpen(true);
    };

    const handleGuideClose = () => {
        setGuideOpen(false);
    };

    useEffect(() => {
        const fetchSessionValues = () => {
            const storedCid = sessionStorage.getItem('cid');
            const storedUid = sessionStorage.getItem('uid');
            if (storedCid) {
                setCid(storedCid);
            }
            if (storedUid) {
                setUid(storedUid);
            }
        };
        const handleGuideOpen = () => {
            setGuideOpen(true);
        };

        const handleGuideClose = () => {
            setGuideOpen(false);
        };

        const fetchData = async () => {
            await fetchSessionValues();
            await fetchTranslationCredits();
            await fetchAvailableCredits();
            await fetchTeamMembers();
            await fetchDictionaryTerms();
            await fetchTranslations();
        };
        const fetchSubscriptionDetails = () => {
           
            setSubscriptionDaysLeft(34); // Set your initial value here
        };



        fetchData();
        fetchSubscriptionDetails();
    }, [cid, uid]);

    const fetchTranslationCredits = async () => {

        setTotalCredits(10000);

    };

    const fetchAvailableCredits = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/dashboard/available-credits`, { uid });
            setAvailableCredits(response.data.available_credits);
            console.log(response.data.available_credits)
        } catch (error) {
            console.error('Error fetching available credits:', error);
        }
    };

    const fetchTeamMembers = async () => {
        try {
            if (!cid) {
                console.error('CID is null, cannot fetch team members.');
                return;
            }
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/manageMember/getTeam-members`, {
                params: { cid }
            });
            setTeamMembers(response.data.total_count);
        } catch (error) {
            console.error('Error fetching team members:', error);
        }
    };

    const fetchDictionaryTerms = async () => {
        try {
            if (uid && (!cid || cid === "null")) {
                console.log('Fetching personal dictionary terms for UID:', uid);
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/dictionary/getPersonal-dict`, { uid });
                setDictionaryTerms(response.data.terms.length);
            } else if (uid && cid && cid !== "null") {
                console.log('Fetching dictionary terms for CID:', cid);
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/dictionary/getDictionary-terms`, { cid });
                setDictionaryTerms(response.data.terms.length);
            } else {
                console.error('Neither CID nor UID is present.');
            }
        } catch (error) {
            console.error('Error fetching dictionary terms:', error);
        }
    };

    const fetchTranslations = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/translation/history`, { uid });
            setTranslations(response.data.translation_details);
            setTranslationsCount(response.data.translation_details.length);

            if (response.data.translation_details && response.data.translation_details.length > 0) {
                const processedData = processTranslationData(response.data.translation_details);
                setChartData(processedData);
            }
        } catch (error) {
            console.error('Error fetching translations:', error);
        }
    };

    const processTranslationData = (translationDetails) => {
        const dateCounts = {};
        translationDetails.forEach(translation => {
            const date = new Date(translation.created_at).toLocaleDateString('en-US', { weekday: 'long' });
            dateCounts[date] = (dateCounts[date] || 0) + 1;
        });

        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const counts = days.map(day => dateCounts[day] || 0);

        return {
            labels: days,
            datasets: [{
                label: 'Translations per Day',
                data: counts,
                fill: false,
                backgroundColor: '#8133F1',
                borderColor: '#8133F1',
            }]
        };
    };
    const [subscriptionDaysLeft, setSubscriptionDaysLeft] = useState(34); // Initial value
    const totalDays = 150;
    // const daysLeft = 35;
    // const totalDays = 100;
    // const percentage = ((totalDays - daysLeft) / totalDays) * 100;

    return (
        <>
            <Navbar />
            <Container maxWidth="x2" style={{ marginTop: '60px', paddingLeft: '2%', paddingRight: '2%' }}>
                <Grid container spacing={3} style={{ height: '100%' }}>
                    <Grid item xs={12} sm={6} md={3} style={{ marginBottom: '1%' }}>

                        <Paper elevation={3} style={{
                            padding: '3%',
                            borderRadius: '10px',
                            background: 'linear-gradient(to left, #CEB0FA, #8133F1)',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            textAlign: 'left',
                        }}>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                <img src={totalcreditsImage} alt="Credits"  style={{  width: '30px', height: '30px', marginRight: '10px' }} />
                                <Typography color='white' variant="h6" fontWeight={'bold'}>{totalCredits}</Typography>
                            </div>
                            <Typography color='white' fontWeight={'bold'}>Total Translation Credits</Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} style={{ marginBottom: '1%' }}>
                        <Paper elevation={3} style={{
                            padding: '3%',
                            borderRadius: '10px',
                            background: 'linear-gradient(to left, #CEB0FA, #8133F1)',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            textAlign: 'left',
                        }}>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                <img src={availcreditsImage} alt="Credits" style={{ width: '25px', height: '30px', marginRight: '10px' }} />
                                <Typography variant="h6" color='white' fontWeight={'bold'}>{availableCredits}</Typography>
                            </div>
                            <Typography color='white' fontWeight={'bold'}> Available Credits</Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} style={{ marginBottom: '1%' }}>
                        <Paper elevation={3} style={{
                            padding: '3%',
                            borderRadius: '10px',
                            background: 'linear-gradient(to left, #CEB0FA, #8133F1)',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            textAlign: 'left',
                        }}>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                <img src={dictTermsImage} alt="Credits" style={{ width: '20px', height: '30px', marginRight: '10px' }} />
                                <Typography variant="h6" color='white' fontWeight={'bold'}>{dictionaryTerms}</Typography>
                            </div>
                            <Typography color='white' fontWeight={'bold'}>Dictionary Terms</Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} style={{ marginBottom: '1%' }}>
                        <Paper elevation={3} style={{
                            padding: '3%',
                            borderRadius: '10px',
                            background: 'linear-gradient(to left, #CEB0FA, #8133F1)',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            textAlign: 'left',
                        }}>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                <img src={groupImage} alt="Credits" style={{ width: '24px', height: '30px', marginRight: '10px' }} />
                                <Typography variant="h6" color='white' fontWeight={'bold'}>{teamMembers}</Typography>
                            </div>
                            <Typography color='white' fontWeight={'bold'}>Current Team Members</Typography>
                        </Paper>
                    </Grid>

                    {/* Translation Chart */}
                    <Grid item xs={12} md={8} style={{ marginTop: '1%' }}>
                        <Paper elevation={3} style={{ padding: '20px' }}>
                            <Typography variant="h6">Translations per Day</Typography>
                            <div style={{ height: '53vh', width: '100%' }}>
                                {chartData ? (
                                    <Line data={chartData} options={{ maintainAspectRatio: false }} />
                                ) : (
                                    <Typography >No data available</Typography>
                                )}
                            </div>
                        </Paper>
                    </Grid>

                    {/* Subscription Plan Progress Bar */}
                    <Grid item xs={12} md={4} style={{ marginTop: '1%' }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Paper elevation={3} style={{ padding: '10px', textAlign: 'center', height: '26vh', }}>
                                    <Typography variant="h6">Subscription Plan</Typography>
                                    <CircularProgressBar initialDaysLeft={subscriptionDaysLeft} totalDays={totalDays} />
                                </Paper>
                            </Grid>


                            {/* Translations Left Gauge */}
                            <Grid item xs={12} style={{ paddingTop: "0.8rem" }}>
                                <Paper elevation={3} style={{ padding: '4%', display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <MultiColorSemiCircularGauge value={translationsCount} />
                                    <Typography variant="h6" sx={{ textAlign: 'center'  }}>Translations Done</Typography>
                                </Paper>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
                {/* <Box>
                <Button variant="outlined" color="primary" onClick={handleGuideOpen} style={{ float: 'right' }}>
                            Guide Me
                        </Button>
                </Box> */}
            </Container>
            {/* <GuideMe open={guideOpen} handleClose={handleGuideClose} /> */}
        </>
    );
};

export default Dashboard;
