import React, { useState, useEffect } from 'react';
import { Container, Box, Typography, TextField, Link, Button, useMediaQuery } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import image from '../assets/Verification.png';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
//require('dotenv').config();


const theme = createTheme();

const VerificationPage = () => {
  const [verificationError, setVerificationError] = useState('');
  const [otpSent, setOtpSent] = useState(false); // State to track OTP sent status
  const navigate = useNavigate();
  const location = useLocation();
  const { otp: initialOtp, uid, email } = location.state || {}; // Get email from state
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [verificationCode, setVerificationCode] = useState(['', '', '', '']);
  const [otp, setOtp] = useState(initialOtp); // Store OTP in local state

  useEffect(() => {
    console.log('Initial OTP:', initialOtp);
    console.log('UID:', uid);
    console.log('Email:', email); // Log email for debugging
  }, [initialOtp, uid, email]);

  // Function to handle change in verification code inputs
  const handleCodeChange = (event, index) => {
    const newCode = [...verificationCode];
    newCode[index] = event.target.value.slice(-1);
    setVerificationCode(newCode);

    // Auto focus on the next input field after entering a digit
    if (event.target.value && index < 3) {
      const nextInput = document.querySelector(`input[name="code-${index + 1}"]`);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  // Function to handle backspace to navigate to previous input field
  const handleKeyDown = (event, index) => {
    if (event.key === 'Backspace' && !verificationCode[index]) {
      if (index > 0) {
        const prevInput = document.querySelector(`input[name="code-${index - 1}"]`);
        if (prevInput) {
          prevInput.focus();
          const newCode = [...verificationCode];
          newCode[index - 1] = '';
          setVerificationCode(newCode);
        }
      }
    }
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    const code = verificationCode.join('');
    const trimmedCode = code.trim();
    const trimmedOtp = otp ? otp.toString().trim() : '';

    console.log('Entered Code:', trimmedCode);
    console.log('Expected OTP:', trimmedOtp);

    // Check if the entered code is not empty and matches the OTP
    if (!trimmedCode || trimmedCode.length < 4) {
      // Display error if the code is incomplete
      setVerificationError('Please Enter the OTP');
    } else if (trimmedCode === trimmedOtp) {
      // Navigate to PasswordReset page if OTP is verified
      const data = { uid };
      console.log('OTP verified, navigating to PasswordReset with data:', data);
      navigate('/PasswordReset', { state: data });
    } else {
      // Display error if OTP is incorrect
      setVerificationError('Invalid OTP');
    }
  };

  // Function to handle Enter key press for submission
  const handleKeyDownEnter = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };

  // Function to resend verification code
  const handleResend = async (e) => {
    e.preventDefault();
    console.log('Resending verification code...');

    try {
      // Simulate an API call to resend OTP
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/resend-otp`, { email });

      if (response.status === 200) {
        const newOtp = response.data.otp; // Get new OTP from response
        console.log('New OTP:', newOtp);

        setOtp(newOtp); // Update OTP in state
        setVerificationCode(['', '', '', '']); // Clear previous verification code
        setVerificationError(''); // Clear previous error message
        setOtpSent(true); // Update OTP sent status
        setTimeout(() => setOtpSent(false), 5000); // Reset otpSent after 5 seconds
        console.log('OTP resent successfully.');
      } else {
        // Handle resend failure
        console.log('Failed to resend OTP. Please try again.');
        setVerificationError('Failed to resend OTP. Please try again.');
      }
    } catch (error) {
      // Handle any unexpected errors
      console.error('Error resending OTP:', error);
      setVerificationError('An error occurred while resending OTP. Please try again.');
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: '100%', height: isSmallScreen ? '20vh' : '30vh', backgroundColor: '#8133F1', display: 'flex' }}>
      </Box>
      <Container component="main" sx={{ display: 'flex', justifyContent: 'center' }}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: -7,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            border: '2px',
            borderRadius: '10px',
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            padding: '20px',
            width: isSmallScreen ? '90vw' : '30vw',
            height: 'fit-content'
          }}
        >
          <Typography sx={{ display: 'flex', justifyContent: 'center', mt: 0 }}>
            <img src={image} alt="Verification Image" style={{ width: isSmallScreen ? '20vw' : '4.5vw', height: isSmallScreen ? '7vh' : '9vh' }} />
          </Typography>
          <Typography variant="h6" component="h1" gutterBottom sx={{ mt: 1 }}>
            Verification
          </Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>
            Enter Verification Code
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', justifyContent: 'center', mb: 2, mt: 2 }}>
            {verificationCode.map((value, index) => (
              <TextField
                key={index}
                name={`code-${index}`}
                value={value}
                onChange={(event) => handleCodeChange(event, index)}
                onKeyDown={(event) => handleKeyDown(event, index)}
                onKeyPress={handleKeyDownEnter}
                variant="outlined"
                inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
                sx={{
                  mx: 1,

                  mb: 2,
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'rgba(0, 0, 0, 0.38)', // grey 600
                    },
                    '&:hover fieldset': {
                      borderColor: 'rgba(0, 0, 0, 0.62)', // grey 800
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'rgba(0, 0, 0, 0.62)', // grey 800
                    },
                  },
                  width: isSmallScreen ? 40 : 50
                }}
              />
            ))}
          </Box>
          {verificationError && (
            <Box display="flex" justifyContent="center" mt={2}>
              <Typography variant="body2" color="error">
                {verificationError}
              </Typography>
            </Box>
          )}
          <Typography variant="body2" component="p" gutterBottom sx={{ textTransform: "none" }}>
            {otpSent ? 'OTP Sent Successfully' : `If you don't receive the code, `}
            <Box sx={{ width: '100%', textAlign: 'center', mt: 1, mb: 1 }}>
              <Link
                component={RouterLink}
                onClick={handleResend}
                // variant="body2"
                sx={{
                  color: "#8133F1", // Default color
                  textDecoration: 'none',
                  borderBottom: '1px solid #8133F1',
                  '&:hover': {
                    color: "#5900D9", // Color on hover
                    borderBottomColor: "#5900D9",
                  },
                }}
              >
                Resend
              </Link>
            </Box>

          </Typography>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 2, mb: 2, borderRadius: '5px',
                fontSize: '14px',  // Minimum text size requirement
                minWidth: '106px',
                height: '36px',
                padding: '0 16px',  // Minimum padding left-right
                '&:hover': {
                  backgroundColor: '#5900D9',
                },
                '& .MuiButton-label': {
                  textAlign: 'center',
                }, 
              backgroundColor: '#8133F1', width: isSmallScreen ? '100%' : '80%', textTransform: 'none' }}
            onClick={handleSubmit} // Updated to call handleSubmit on click
          >
            Verify
          </Button>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default VerificationPage;

