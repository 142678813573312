import React, { useState, useEffect } from 'react';
import { Box, TextField, Typography, Button, Modal } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import axios from 'axios';

const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#30119E',
  color: '#FFFFFF',
  '&:hover': {
    backgroundColor: 'primary',
  },
}));

const CloseButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: '5px',
  right: '5px',
  borderRadius: '100%',


}));

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&:active': {
    backgroundColor: 'transparent',
  },
  '&:focus': {
    backgroundColor: 'transparent',
  },
}));

const AddTermForm = ({ onClose, onTermAdded, assets }) => {
  const [textFieldContent, setTextFieldContent] = useState('');
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    setOpen(true);
  }, []);

  const handleClose = () => {
    onClose();
  };

  const handleTextFieldChange = (event) => {
    setTextFieldContent(event.target.value);
  };

  const saveTermToBackend = async (term) => {
    try {
      const uid = sessionStorage.getItem('uid'); // Retrieve UID from session storage
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/dictionary/addDictionary/term`, {
        uid,
        term,
      });
      return response.data;
    } catch (error) {
      console.error('Error saving term:', error);
      throw error;
    }
  };



  const handleSubmit = async () => {
    try {
      // Check if the term already exists in the assets list
      const isDuplicate = assets.some(asset => asset.term.toLowerCase() === textFieldContent.toLowerCase());
      if (isDuplicate) {
        setSnackbarMessage('Duplicate term found. Please add another term.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }
      if (textFieldContent.trim() === '') {
        setSnackbarMessage('Please enter a term.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }

      const response = await saveTermToBackend(textFieldContent);
      if (response.success) {
        setSnackbarMessage(response.message);
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        onTermAdded(response.term);
        handleClose();
      } else {
        console.error('Error saving term:', response.message);
        setSnackbarMessage('Failed to save term. Please try again.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Error saving term:', error);
      setSnackbarMessage('Failed to save term. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };


  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleReset = () => {
    setTextFieldContent('');
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="new-job-modal"
        aria-describedby="modal-to-create-new-job"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '300px',
            backgroundColor: '#e7ebf8',
            borderRadius: '5px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
            padding: '20px',
            textAlign: 'left',
            position: 'relative',

          }}
        >
          {/* <CloseButton onClick={handleClose}><CloseIcon /></CloseButton> */}
          <Typography sx={{ mb: 2, fontSize: "15px", fontWeight: "bold" }}>
            Add Term
          </Typography>
          {/* <TextField
            placeholder="Enter Term"
            value={textFieldContent}
            onChange={handleTextFieldChange}
            fullWidth
            required
            InputLabelProps={{ shrink: false }}
            sx={{ marginBottom: '20px', backgroundColor: 'white'
 
            }}
            InputProps={{
              style: {
                backgroundColor: 'white',
                fontFamily: 'Poppins, sans-serif',
                fontSize:"13px",
                height: '2.2rem',
              },
              inputProps: {
                style: {
                  paddingTop: '20px',
                },
              },
            }}
          /> */}
          <TextField
            placeholder="Enter Term"
            value={textFieldContent}
            onChange={handleTextFieldChange}
            fullWidth
            required
            type="email"
            sx={{
              mb: 2,
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'rgba(0, 0, 0, 0.38)', // grey 600
                },
                '&:hover fieldset': {
                  borderColor: 'rgba(0, 0, 0, 0.62)', // grey 800
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'rgba(0, 0, 0, 0.62)', // grey 800
                },
              },
            }}
            InputProps={{
              style: {
                backgroundColor: 'white',
                fontFamily: 'Poppins, sans-serif',
                height: '2.2rem',
                marginTop: '-6px',
                fontSize: "13px",
              },
              inputProps: {
                style: {
                  paddingTop: '20px',
                },
              },
            }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'right', gap: 1 }}>
            <CustomButton
              variant="contained"
              sx={{
                textTransform: "none",
                minWidth: '48px',
                height: '21px',
                '&:hover': {
                  backgroundColor: '#5900D9',
                },
                '& .MuiButton-label': {
                  textAlign: 'center',
                },
                // width: "60px",
                // height: "30px",
                fontSize: "11px",
                backgroundColor: '#8133F1',
              }}
              color="primary"
              onClick={onClose}
            >
              Cancel
            </CustomButton>
            <CustomButton
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleSubmit}
              sx={{
                textTransform: "none",
                minWidth: '48px',
                height: '21px',
                '&:hover': {
                  backgroundColor: '#5900D9',
                },
                '& .MuiButton-label': {
                  textAlign: 'center',
                },
                fontSize: "11px",
                // width: "60px",
                // height: "30px",
                // padding: "6px 12px",
                backgroundColor: '#8133F1',
              }}
            >
              Save
            </CustomButton>

          </Box>
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={
            snackbarSeverity === 'success'
              ? {
                backgroundColor: '#F8F8F8',
                color: '#878585',
                border: '1px solid #0DC42B',
                borderRadius: '4px',
                boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.3)',
              }
              : {
                backgroundColor: '#F8F8F8',
                color: 'red',
                border: '1px solid red',
                borderRadius: '4px',
                boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.3)',
              }
          }
          iconMapping={{
            success: <CheckCircleIcon fontSize="inherit" style={{ color: '#16D835' }} />,
            error: <ErrorIcon fontSize="inherit" style={{ color: 'red' }} />,
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {/* <Modal
        open={savePopupOpen}
        onClose={handleSavedPopupClose}
        aria-labelledby="save-modal"
        aria-describedby="modal-after-save"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '300px',
            backgroundColor: '#D2D2FF',
            borderRadius: '10px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
            padding: '20px',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h6" component="p" sx={{ mb: 2 }}>
            Term Saved
          </Typography>
          <CheckCircleOutlineIcon color="success" sx={{ fontSize: '48px', mb: 2 }} />
          <CustomButton variant="contained" onClick={handleSavedPopupClose} sx={{ mt: 2 }}>
            Close
          </CustomButton>
        </Box>
      </Modal> */}

      {/* <Modal
        open={errorPopupOpen}
        onClose={handleErrorPopupClose}
        aria-labelledby="error-modal"
        aria-describedby="modal-on-error"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '300px',
            backgroundColor: '#FFD2D2',
            borderRadius: '10px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
            padding: '20px',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" component="p" sx={{ mb: 2 }}>
            Error Saving Term
          </Typography>
          <ErrorIcon color="error" sx={{ fontSize: '48px', mb: 2 }} />
          <CustomButton onClick={handleErrorPopupClose}>
            Close
          </CustomButton>
        </Box>
      </Modal> */}




    </div>






  );
};

export default AddTermForm;