import React from 'react';
import { Button, Typography, Box } from '@mui/material';
import backgroundimage from '../assets/feded.jpg';
import { useNavigate } from 'react-router-dom';
 
const Home = () => {
  const navigate = useNavigate();
 
  const handleregisterClick = () => {
    navigate('/Register');
  }
  const handleloginClick = () => {
    navigate('/Login');
  }
 
  return (
    <Box
      sx={{
        minHeight: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        textAlign: 'left',
        position: 'relative',
        overflow: 'hidden',
        padding: '40px', // Add padding to the Box for some space from the edges
        boxSizing: 'border-box',
        overflowX: 'hidden'
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: `url(${backgroundimage}) no-repeat center center`,
          backgroundSize: 'cover',
          zIndex: -1,
        }}
      />
      <Box
        sx={{
          marginTop: '20px', // Adjust this value to move the text up slightly
          color: '#FFFFFF',
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom sx={{ marginBottom: '1px', marginTop: "10%" }}>
          Your go-to platform for document and sentence translation, plus
        </Typography>
        <Typography variant="h4" component="h1" gutterBottom sx={{ marginBottom: '40px' }}>
          seamless internal vocabulary management.
        </Typography>
        <Typography variant="h6" component="p" gutterBottom sx={{ marginBottom: '40px' }}>
          Translate with precision, communicate globally, and ensure consistency across your organization's unique terminology.
        </Typography>
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#8133F1',
              color: 'white',
              marginRight: '20px',
              width: {
                xs: '100%', // full width on extra small screens
                sm: '50%', // half width on small screens
                md: '25%', // one-fourth width on medium screens
                lg: '10%', // one-fifth width on large screens
                xl: '10%', // one-tenth width on extra large screens
              },
              textTransform: "none",
              borderRadius: '5px', 
              '&:hover': {
                backgroundColor: '#5900D9', // Color on hover
              }
            }}
            onClick={handleregisterClick}>
            REGISTER
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#8133F1',
              color: 'white',
              width: {
                xs: '100%', // full width on extra small screens
                sm: '50%', // half width on small screens
                md: '25%', // one-fourth width on medium screens
                lg: '10%', // one-fifth width on large screens
                xl: '10%', // one-tenth width on extra large screens
              },
              textTransform: "none",
              borderRadius: '5px', 
              '&:hover': {
                backgroundColor: '#5900D9', // Color on hover
              }
            }}
            onClick={handleloginClick}>
            LOGIN
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
 
export default Home;